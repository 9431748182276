import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';



import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';


import ReactExport from "react-export-excel";



import { getSessions } from '../../services/sessions';



export default () => {


  /*const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;*/

  const [sessions, setSessions] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("userLoged"));


  const myEventsList = [{
    title: 'Primera conferencia',
    start: new Date(2021, 8, 22),
    end: new Date(2021, 8, 22),
    allDay: false,
    resource: '',
  }]


  useEffect( () => {
    async function fetchData() {
      const sessionsResolve = await getSessions('Activas');
      let sessions = [];
      sessionsResolve.forEach((session) => {
 
        sessions.push({ title: session.data().name, 
          start: session.data().sessionDate.toDate() });
        console.log(session.id, " => ", session.data());
      });
      setSessions(sessions);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Calendario TMC</h4>
          {(user.role === 'super' || user.role === 'admin') && <p className="mb-0">Revisa y crea las fechas de las sesiones.</p>}
          {(user.role === 'student' || user.role === 'student') && <p className="mb-0">Revisa las fechas de las sesiones.</p>}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
        {/*<ButtonGroup>
         <ExcelFile  filename="Relación de alumnas"
  
  element={ <Button  variant="outline-primary" size="sm">Exportar a excel</Button>}>
                <ExcelSheet data={{}} name="Candidatas">
                    <ExcelColumn label="Folio" value={(col) => col.id} />
                    <ExcelColumn label="Nombre" value={(col) => col.data.name} />
                    <ExcelColumn label="Estado" value={(col) => col.data.place} />
                    <ExcelColumn label="Edad" value={(col) => col.data.candidateAge} />
                    <ExcelColumn label="Correo" value={(col) => col.data.email} />
                    <ExcelColumn label="Escuela" value={(col) => col.data.school} />
                    <ExcelColumn label="Turno" value={(col) => col.data.schoolShift} />
                    <ExcelColumn label="¿Prueba lógica?" value={(col) => col.data.finishTest?'Sí':'No'} />
                    <ExcelColumn label="¿Video?" value={(col) => col.data.video!=undefined?'Sí':'No'} />
                    <ExcelColumn label="Status" value={(col) => col.data.status} />

                </ExcelSheet>
            </ExcelFile>
          </ButtonGroup> */}
        </div>
      </div>

      <div className="table-settings mb-4">

    

        
        <Row className="justify-content-between align-items-center">

        {(user.role === 'super' || user.role === 'admin') && <FullCalendar
       
       plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
       initialView="dayGridMonth"
       events={sessions}
       locale={'es'}

       customButtons={{
          new: {
            text: 'Crear sesión',
            click: () =>  window.location.replace("#/dashboard/session/new"),
          },
        }}
    
        headerToolbar={{
        start: 'prev,next today new', 
        center: 'title',
        end: 'dayGridMonth timeGridWeek timeGridDay' 
        }}
        buttonText={{
        today:    'Hoy',
        month:    'Mes',
        week:     'Semana',
        day:      'Día',
        list:     'Lista'
        }}
            />}


{(user.role === 'student' || user.role === 'tutor') && <FullCalendar
       
       plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
       initialView="dayGridMonth"
       events={sessions}
       locale={'es'}
        headerToolbar={{
        start: 'prev,next today', 
        center: 'title',
        end: 'dayGridMonth timeGridWeek timeGridDay' 
        }}
        buttonText={{
        today:    'Hoy',
        month:    'Mes',
        week:     'Semana',
        day:      'Día',
        list:     'Lista'
        }}
            />}
        
        </Row>  
 
      

      </div>



  

     
    </>
  );
};
