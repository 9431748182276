
import React, { useState, useEffect } from "react";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faEnvelope, faUser, faCalendar, faUnlockAlt, faSchool } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, Modal, Container, InputGroup, Image, Card } from '@themesberg/react-bootstrap';

import {storage,ref,uploadBytesResumable,getDownloadURL } from "../../Firebase";



import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Routes } from "../../routes";
import Logo from "../../assets/img/pages/Banner-01.jpg";




import { registerStudentsCandidate, createStudent, getStudentCandidate } from '../../services/students';






export default () => {

  
    const [validated, setValidated] = useState(false);
    const [validate, setValidate] = useState(false);

    const [showDefault, setShowDefault] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showContinue, setShowContinue] = useState(false);
    const [showFinishTest, setShowFinishTest] = useState(false);
    const [studentAlreadyRegistered, setStudentAlreadyRegistered] = useState(false);
    const [studentDuplicated, setStudentDuplicated] = useState(false);


    const [name, setName] = useState('');
    const [candidateAge, setCandidateAge] = useState(0);
    const [disabledGI, setDisableGI] = useState(false);

    const [email, setEmail] = useState('');

    const [place, setPlace] = useState('');

    const [school, setSchool] = useState('');

    const [video, setVideo] = useState([]);
    const [idStudentRegistered, setIdStudentRegistered] = useState('');
    const [status, setStatus] = useState('');




    const saveGeneralInformation = async (event) => {
      alert('registrando ...')

      event.preventDefault();
      setValidated(true);
      const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return null;
    }

      const student = await getStudentCandidate(email.toLowerCase());
      if(student != null){
        alert('Ya hay un registro con ese correo ');
        return null;
      }


            const studenRegist = {name,"email":email.toLowerCase(),
            candidateAge,place,school,
            'documentSchool':'',score:0,status:"Inscrita"};
      
            const studentRegister = await registerStudentsCandidate(studenRegist);
            if(studentRegister != null){
              setIdStudentRegistered(studentRegister.id); 
              const userCreated = await createStudent(studentRegister.id,name,email );
              setShowDefault(true);
            }
           
           

           
         

     
    }

  

    const handleClose = () => {
        //setIdStudentRegistered('');
        setName('');
        setCandidateAge('');
        setEmail('');
        setPlace('');
        setSchool('');
        setValidate(false);
        setShowDefault(false);
        
    }



    





  return (
    <main  style={{backgroundColor:'#CEC7F2'}}>

<Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
      <Modal.Header>
    
        <Modal.Title className="h2" style={{textAlign: 'center'}}> {!studentAlreadyRegistered ?'¡Felicidades te has registrado satisfactoriamente!':'Hola, el correo que ingresaste ya cuenta con una registro.'}</Modal.Title>
        
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
      <center><p><b>Alumna registrada correctamente.</b></p></center>

        <center><h4>Folio del registro</h4><b style={{color:'#5F4D9E', fontSize:28}}>{idStudentRegistered}</b></center>

      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
      
    </Modal>

  



      <center><Image src={Logo} width={'100%'} /></center> <br/>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" >
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-900">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h2 className="mb-0" style={{color:'#5F4D9E'}}>Registro directo de alumnas a Talentum Mujeres 2021</h2>
                 

                  <Form className="mt-4" onSubmit={saveGeneralInformation}>

                  <Form.Group id="name" className="mb-4">
                    <Form.Label style={{float:'left'}}>Nombre completo</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control 
                      value={name}  onChange={evt => setName(evt.target.value)}
                      autoFocus required  placeholder="Nombre completo" />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="age" className="mb-4">
                    <Form.Label style={{float:'left'}}>Edad</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faCalendar} />
                      </InputGroup.Text>
                      <Form.Control 
                      value={candidateAge}  onChange={evt => setCandidateAge(evt.target.value)}
                      autoFocus required type="number" placeholder="Número en años" />
                    </InputGroup>
                  </Form.Group>


                  <Form.Group id="email" className="mb-4">
                    <Form.Label style={{float:'left'}}>Correo eléctronico</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control 
                      value={email}  onChange={evt => setEmail(evt.target.value)}
                      autoFocus required type="email" placeholder="correo@dominio.com" />
                    </InputGroup>
                  </Form.Group>


                  <Form.Group id="school" className="mb-4">
                    <Form.Label style={{float:'left'}}>Escuela</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSchool} />
                      </InputGroup.Text>
                      <Form.Control 
                      value={school}  onChange={evt => setSchool(evt.target.value)}
                      autoFocus required placeholder="Nombre de la escuela" />
                    </InputGroup>
                  </Form.Group>




                  <Form.Group id="place" className="mb-4" >
                    <Form.Label  style={{float:'left'}}>Estado</Form.Label>
                    <InputGroup>
                    <Form.Select   value={place}  onChange={evt => setPlace(evt.target.value)}>
                        <option defaultValue value={''}>Selecciona una opción</option>
                        <option>Aguascalientes</option>
                        <option>Baja California</option>
                        <option>Baja California Sur</option>
                        <option>Campeche</option>
                        <option>Chiapas</option>
                        <option>Chihuahua</option>
                        <option>Ciudad de México</option>
                        <option>Coahuila</option>
                        <option>Colima</option>
                        <option>Durango</option>
                        <option>Estado de México</option>
                        <option>Guanajuato</option>
                        <option>Guerrero</option>
                        <option>Hidalgo</option>
                        <option>Jalisco</option>
                        <option>Michoacán</option>
                        <option>Morelos</option>
                        <option>Nayarit</option>
                        <option>Nuevo León</option>
                        <option>Oaxaca</option>
                        <option>Puebla</option>
                        <option>Querétaro</option>
                        <option>Quintana Roo</option>
                        <option>San Luis Potosí</option>
                        <option>Sinaloa</option>
                        <option>Sonora</option>
                        <option>Tabasco</option>
                        <option>Tamaulipas</option>
                        <option>Tlaxcala</option>
                        <option>Veracruz</option>
                        <option>Yucatán</option>
                        <option>Zacatecas</option>
                    </Form.Select>
                    </InputGroup>
                  </Form.Group>
                
                  
     

                  <Button variant="primary" type="submit" className="w-100">
                    Registrar
                  </Button>
                </Form>



                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
