import React, { useState, useEffect } from "react";
import { Row, Button, ButtonGroup } from '@themesberg/react-bootstrap';

import ReactExport from "react-export-excel";

import { TutorsCandidatesTable } from "../../components/Tables";


import { getTutorsCandidate } from '../../services/tutors';




export default () => {

  const [tutorsCandidates, setTutorsCandidates] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


  useEffect( () => {


    async function fetchData() {
      
      const tutorsCandidates = await getTutorsCandidate();
      let arrayTutors = [];

      tutorsCandidates.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        arrayTutors.push({id:doc.id, data: doc.data() });
        console.log(doc.id, " => ", doc.data());
      });

      setTutorsCandidates(arrayTutors);
    }
    fetchData();




  }, []);



  const downloadExcel = () => {

    alert('descragando');

    return(

    <ExcelFile>
                <ExcelSheet data={tutorsCandidates} name="tutores">
                    <ExcelColumn label="Nombre" value="name"/>
                    <ExcelColumn label="Edad" value="data.age"/>
                </ExcelSheet>
            </ExcelFile>
    )
  }

  return (
    <>


    
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Candidatos a tutores</h4>
          <p className="mb-0">Busca y selecciona a los candidatos más destacados.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         <ButtonGroup>
         <ExcelFile  filename="Relación de candidatos no seleccionados"
  
  element={ <Button  variant="outline-primary" size="sm">Exportar a excel</Button>}>
                <ExcelSheet data={tutorsCandidates} name="Candidatos a tutores">

                    <ExcelColumn label="Nombre" value={(col) => col.data.name} />
                    <ExcelColumn label="Edad" value={(col) => col.data.candidateAge} />
                    <ExcelColumn label="Correo" value={(col) => col.data.email} />
                    <ExcelColumn label="Universidad" value={(col) => col.data.university} />
                </ExcelSheet>
            </ExcelFile>
          </ButtonGroup> 
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
         {/**  <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Buscar" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>  */}
        </Row>
      </div>

      <TutorsCandidatesTable tutorsCandidates={tutorsCandidates} />
    </>
  );
};
