
import React, { useState, useEffect } from "react";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, Modal, Container, InputGroup, Image } from '@themesberg/react-bootstrap';

import {storage,ref,uploadBytesResumable,getDownloadURL } from "../../Firebase";



import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Routes } from "../../routes";
import Logo from "../../assets/img/pages/Banner-01.jpg";
import P1 from "../../assets/img/pages/p1.png";
import P2 from "../../assets/img/pages/p2.png";
import P3 from "../../assets/img/pages/p3.png";
import P4 from "../../assets/img/pages/p4.png";
import P5 from "../../assets/img/pages/p5.png";
import P6 from "../../assets/img/pages/p6.png";
import P7 from "../../assets/img/pages/p7.png";
import P8 from "../../assets/img/pages/p8.png";
import P9 from "../../assets/img/pages/p9.png";
import P10 from "../../assets/img/pages/p10.png";
import P11 from "../../assets/img/pages/p11.png";
import P12 from "../../assets/img/pages/p12.png";



import { registerStudentsCandidate, getStudentCandidate, studentCandidateStartTest, registerTestStudentsCandidate, uploadVideoPresentation, addVideoPresentationURL } from '../../services/students';



const minuteSeconds = 60;
const hourSeconds = 3600;


const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;





const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6
};


const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div  style={{fontSize: 32}}>{time}</div>
      <div>{dimension}</div>
    </div>
  );
};


export default () => {

  const [timeEnd, setTimeEnd] = useState(false);



    useEffect(() => {

      if(timeEnd){
        //alert('time end!!!!');
        saveTestInformation();
      }

     
    
    }, [timeEnd]); 

  
    const [validated, setValidated] = useState(false);
    const [validate, setValidate] = useState(false);


    const [showPolitics, setShowPolitics] = useState(false);
    const [showHonesty, setShowHonesty] = useState(false);
    const [noticePrivacity,setNoticePrivacity] = useState(false);
    const [key, setKey] = useState('visual_design');
    const [testBegin, setTestBegin] = useState(false);
    const [testCompleted, setTestCompleted] = useState(false);
    const [uploadValue,setUploadValue] = useState(0);
    const [emailContinue, setEmailContinue] = useState('');
    const [userNotFound,setUserNotFound] = useState(false);
    const [numOfComplete,setNumOfComplete] = useState(0);


    const [beginTime, setBeginTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [remainingTime,setRemainingTime] = useState(null);


    const [showDefault, setShowDefault] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showContinue, setShowContinue] = useState(false);
    const [showFinishTest, setShowFinishTest] = useState(false);
    const [studentAlreadyRegistered, setStudentAlreadyRegistered] = useState(false);
    const [studentDuplicated, setStudentDuplicated] = useState(false);


    const [name, setName] = useState('');
    const [disabledGI, setDisableGI] = useState(false);
    const [email, setEmail] = useState('');
    const [birthday, setBirthday] = useState('');
    const [place, setPlace] = useState('');
    const [typeSchool, setTypeSchool] = useState('');
    const [school, setSchool] = useState('');
    const [schoolShift, setSchoolShift] = useState('');
    const [documentSchool, setDocumentSchool] = useState('');
    const [q1, setQ1] = useState('');
    const [q2, setQ2] = useState('');
    const [q3, setQ3] = useState('');
    const [q4, setQ4] = useState('');
    const [q5, setQ5] = useState('');
    const [q6, setQ6] = useState('');
    const [q7, setQ7] = useState('');
    const [q8, setQ8] = useState('');
    const [q9, setQ9] = useState('');
    const [q10, setQ10] = useState('');
    const [q11, setQ11] = useState('');
    const [q12, setQ12] = useState('');
    const [video, setVideo] = useState([]);
    const [idStudentRegistered, setIdStudentRegistered] = useState('');
    const [status, setStatus] = useState('');


 


    const checkIfExistStudent = async () => {

      


      if(emailContinue != ''){

        const student = await getStudentCandidate(emailContinue.toLowerCase());


      if( JSON.stringify(student) !== '{}' && student !== null  ){
        //alert(' existe'+ JSON.stringify(student.data()));

        const studentLoaded = student.data();
        setShowDefault(true);

        //alert(student.id)
        setIdStudentRegistered(student.id);
        setDisableGI(true);
        setName(studentLoaded.name);
        setBirthday(studentLoaded.birthday.toDate());
        setPlace(studentLoaded.place);
        setSchool(studentLoaded.school);
        setTypeSchool(studentLoaded.typeSchool);
        setSchoolShift(studentLoaded.schoolShift);
        setDocumentSchool(studentLoaded.documentSchool);
        setStatus(studentLoaded.status);
        setShowContinue(false);
        setUserNotFound(false);


        if(studentLoaded.status === "Registrado"){
          setStudentAlreadyRegistered(true);
          
          return null;
        }


        if(studentLoaded.finishTest){
          setTestCompleted(true);
          setKey('user_experience');
          return null;
        }



        if(studentLoaded.finishTime != null && studentLoaded.startTime != null){
          setTestBegin(true);
          const endTime = studentLoaded.finishTime;
          const stratTime = studentLoaded.startTime;
          let remainingTime = 0;


         // alert(new Date()+' < '+endTime.toDate())
  
          if(new Date() < endTime.toDate()){

            remainingTime = endTime - ( Date.now() / 1000);

          }else{
            setTestCompleted(true);
          }
   
          setRemainingTime(remainingTime);
          setBeginTime(stratTime.toDate());
          setEndTime(endTime.toDate());

        }else{
          setTestBegin(false);
          
          //In strange cases when not generate times in first time
          
          /*let startTime = new Date();
          let finishTime = new Date();
  
          finishTime.setMinutes(finishTime.getMinutes() + 12); // timestamp
          finishTime = new Date(finishTime); // Date object
  
          await studentCandidateStartTest(student.id,startTime,finishTime);

          const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
          const endTime = stratTime + 722; // use UNIX timestamp in seconds
          const remainingTime = endTime - stratTime;
          setRemainingTime(remainingTime);
          setBeginTime(startTime);
          setEndTime(finishTime);*/



        }

       
        setKey('code_friendly');





      }else{
        setUserNotFound(true);
        setDisableGI(false);
        setIdStudentRegistered('');
        setName('');
        setBirthday('');
        setTypeSchool('');
        setPlace('');
        setSchool('');
        setSchoolShift('');
        setDocumentSchool('');
        setQ1('');
        setQ2('');
        setQ3('');
        setQ4('');
        setQ5('');
        setQ6('');
        setQ7('');
        setQ8('');
        setQ9('');
        setQ10('');
        setQ11('');
        setQ12('');
        setVideo([]);

       
      
      }

      }
      
    }


    const saveGeneralInformation = async (event) => {

      event.preventDefault();



      setValidated(true);
      const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return null;
    }

    

    



      const student = await getStudentCandidate(email.toLowerCase());

      if( JSON.stringify(student) !== '{}' && student !== null  ){
        setStudentDuplicated(true);
        setEmailContinue(email.toLowerCase());
        setShowContinue(true);
        return null;
      }

      let  candidateAge = getAge(birthday.toDate());
      let score = 0;

      //alert(candidateAge);
      //alert(JSON.stringify(birthday.toDate()));



      var storageRef = ref(storage,`documentsStudents/${documentSchool.name}`);

      const task = uploadBytesResumable(storageRef,documentSchool);

      task.on("state_changed", function(snapshot) {
        var percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        console.log(percent + "% done");
        //setUploadValue(parseInt(percent));
        
        }, null, () => {

          getDownloadURL(storageRef).then(async (downloadURL) => {

            const studenRegist = {name,"email":email.toLowerCase(),"birthday":birthday.toDate(),candidateAge,place,typeSchool,
            place,school,schoolShift,'documentSchool':downloadURL,score,status:"Nuevo",startTest:false, finishTest:false};
      
            const candidateRegisterResponse = await registerStudentsCandidate(studenRegist);
            setKey('code_friendly');
            setIdStudentRegistered(candidateRegisterResponse.id);
            setDisableGI(true);
            setShowDefault(true);
           

           
          });

        });
     
    }

    async function saveTestInformation() {

     if(status !== "Examen" && status !== "Registrado"){
      const studenRegist = {q1,q2,q3,q4,q5,q6,q7,q8,q9,q10,q11,q12,status:"Examen","realFinishTime":new Date(),finishTest:true};
      const candidateRegisterResponse = await registerTestStudentsCandidate(idStudentRegistered,studenRegist);
    }
      setShowFinishTest(false);
      setTestCompleted(true);
      setKey('user_experience');
      if(status === "Registrado"){
        setStudentAlreadyRegistered(true);
        setShowDefault(true);
      }
      
    }


    const startTest = async () => {
        try {
          setTestBegin(true);
          let startTime = new Date();
          let finishTime = new Date();

          finishTime.setMinutes(finishTime.getMinutes() + 12); // timestamp
          finishTime = new Date(finishTime); // Date object

          await studentCandidateStartTest(idStudentRegistered,startTime,finishTime);



          const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
          const endTime = stratTime + 722; // use UNIX timestamp in seconds
          const remainingTime = endTime - stratTime;
          setRemainingTime(remainingTime);

          setBeginTime(startTime);
          setEndTime(finishTime);


      } catch (e) {
          console.error(e);
      }
    }


    const checkTestCompletation = () => {
      setShowFinishTest(true);
      let numOfComplete = 0;

      if(q1 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q2 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q3 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q4 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q5 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q6 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q7 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q8 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q9 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q10 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q11 !== ''){
        numOfComplete = numOfComplete+1;
      }

      if(q12 !== ''){
        numOfComplete = numOfComplete+1;
      }

      setNumOfComplete(numOfComplete);


    }



    const onSubmitVideo = async (e) => {
      e.preventDefault()
      var storageRef = ref(storage,`videosStudents/${video.name}`);

      const task = uploadBytesResumable(storageRef,video);

      task.on("state_changed", function(snapshot) {
        var percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        console.log(percent + "% done");
        setUploadValue(parseInt(percent));
        
        }, null, () => {


          getDownloadURL(storageRef).then((downloadURL) => {
           // alert(downloadURL);
            addVideoPresentationURL(idStudentRegistered,downloadURL).then(() => {
              setShowDefault(true);
            })
           
          });
         
         

         /* task.snapshot.ref.getDownloadURL().then((downloadURL) => {
            alert(downloadURL);

          });*/

         // setShowDefault(true);

        });
  }


    const handleClose = () => {
        //setIdStudentRegistered('');
        setName('');
        setEmail('');
        setBirthday('');
        setTypeSchool('');
        setPlace('');
        setSchool('');
        setSchoolShift('');
        setDocumentSchool('');
        setQ1('');
        setQ2('');
        setQ3('');
        setQ4('');
        setQ5('');
        setQ6('');
        setQ7('');
        setQ8('');
        setQ9('');
        setQ10('');
        setQ11('');
        setQ12('');
        setVideo([]);
        setValidate(false);
        setShowDefault(false);
        
    }



    function getAge(birthDate) {
        var today = new Date();
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }





  return (
    <main  style={{backgroundColor:'#CEC7F2'}}>


<Modal as={Modal.Dialog} size={"lg"} centered show={showPolitics} onHide={() => setShowPolitics(false)}>
      <Modal.Header>


</Modal.Header>
      <Modal.Body>
        <center> <iframe className={'docx'}   width="100%" height="600" frameborder="0" src="https://docs.google.com/document/d/e/2PACX-1vSYt_vK2GOj-WWsCb3BcS1Pp32Y2Ige7kuikiAeX_2V6AaUu5Lgzdl3-KSKQX4weQ/pub?embedded=true"></iframe></center>
      </Modal.Body>
  
      
    </Modal>



    <Modal as={Modal.Dialog} size={"lg"} centered show={showHonesty} onHide={() => setShowHonesty(false)}>
      <Modal.Header>


</Modal.Header>
      <Modal.Body>
        <center> 
        <iframe className={'docx'}   width="100%" height="600" frameborder="0" src="https://docs.google.com/document/d/e/2PACX-1vT24yyBSkCzTSkqCzRhK_I_a_nVKoUz3kbW2BLHC57HPqpneiskwvW9TfddpTh_Jw/pub?embedded=true"></iframe></center>
      </Modal.Body>
  
      
    </Modal>

    



<Modal as={Modal.Dialog} centered show={showHelp} onHide={() => setShowHelp(false)}>
      <Modal.Header>
    
        <Modal.Title className="h2" style={{textAlign: 'center'}}>Instrucciones de registro.</Modal.Title>
        
        <Button variant="close" aria-label="Close" onClick={() => setShowHelp(false)} />
      </Modal.Header>
      <Modal.Body>
       
      <p className="mb-0">

Paso 1: Completar la sección de datos generales. <br/>
Paso 2: Contestar la prueba en menos de 12 minutos.<br/>
Paso 3: Subir un video de presentación de 2 min máximo.<br/><br/>
<b>Completa cada paso en el orden indicado, no puedes avanzar a otra sección antes de terminar la actual.</b><br/><br/>

<b style={{color:'red', fontSize:20}}><center>Atención</center></b> <p style={{color:'red'}}>Puedes hacer tu registro por secciones en distintos momentos, en el caso de la prueba mientras no la inicies podrás continuar con el proceso una vez que registres tus datos generales, de igual forma si aún no grabas tu video pero ya completaste la prueba puedes ingresar posteriormente y subir tu video.</p>


<b style={{fontSize:20}}><center>¿Tienes más dudas? <br/>Escríbenos al correo <a  style={{fontSize:22}} target="_blank" href="mailto:talentum@cide.edu">talentum@cide.edu</a></center></b>
</p>


      </Modal.Body>
  
      
    </Modal>

    <Modal as={Modal.Dialog} centered show={showContinue} onHide={() => setShowContinue(false)}>
    <Modal.Header>
    
    <Modal.Title className="h2" style={{textAlign: 'center'}}> Buscar mi registro</Modal.Title>
    
    <Button variant="close" aria-label="Close" onClick={() => setShowContinue(false)} />
  </Modal.Header>
  <Modal.Body>
      <h5> {studentDuplicated?'Hola, hemos detectado que tu correo ya esta registrado, continua tu proceso de registro':''} </h5>

       <Form.Group id="email" className="mb-4">
                <Form.Label>Correo eléctronico (con el que iniciaste el registro)</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                  <Form.Control  type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" size="30" value={emailContinue} onChange={evt => setEmailContinue(evt.target.value)} autoFocus required type="text" placeholder="Correo eléctronico todo en minúsculas" />
                </InputGroup>
              </Form.Group>


              <Button variant="secondary" style={{margin:10}} type="button" onClick={checkIfExistStudent} className="w-40">
                Continuar mi registro
              </Button>
  

  </Modal.Body>
  <Modal.Footer>
   {userNotFound &&
   
    <center><h5>No se encontró ningún registro con el correo ingresado, revisa que este correctamente escrito, si crees que es un error escribe al equipo de soporte.</h5></center>

   
    }

  </Modal.Footer>
      
    </Modal>


<Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
      <Modal.Header>
    
        <Modal.Title className="h2" style={{textAlign: 'center'}}> {!studentAlreadyRegistered ?'¡Felicidades te has registrado satisfactoriamente!':'Hola, el correo que ingresaste ya cuenta con una registro.'}</Modal.Title>
        
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
      <center><p><b>Desde este momento estas participando para ingresar a Talentum Mujeres 2021 pero si completas la prueba de lógica y subes tú video de presentación tienes más probabilidades de ser seleccionada.</b></p></center>

        <center><h4>Folio del registro</h4><b style={{color:'#5F4D9E', fontSize:28}}>{idStudentRegistered}</b></center>

      </Modal.Body>
      <Modal.Footer>
      <center> <p>Por favor revisa en nuestro sitio <a href="https://www.talentummujeres.org/" target="blank">https://www.talentummujeres.org/</a><br/> los resultados de selección a partir del <b style={{color:'#5F4D9E', fontSize:18}}>12 de Agosto del 2021</b> </p></center>

      </Modal.Footer>
      
    </Modal>

  

    <Modal as={Modal.Dialog} centered show={showFinishTest} onHide={() => setShowFinishTest(false)}>
    <Modal.Header>
    
    <Modal.Title className="h2" style={{textAlign: 'center'}}> ¿Estás seguro que quieres finalizar la prueba?</Modal.Title>
    
    <Button variant="close" aria-label="Close" onClick={() => setShowFinishTest(false)} />
  </Modal.Header>
  <Modal.Body>

      <center><p style={{color:'red', fontSize:20, fontWeight: 'bold'}}>{'Has contestado '+numOfComplete +' de 12 preguntas'}</p></center>

             
  

  </Modal.Body>
  <Modal.Footer>
  <center>
  <Button variant="secondary" style={{margin:10}} type="button" onClick={() => setShowFinishTest(false)} className="w-40">
                No, continuar prueba
              </Button>

              <Button variant="danger" style={{margin:10}} type="button" onClick={() => saveTestInformation()} className="w-40">
                Sí, finalizar prueba
              </Button></center>

  </Modal.Footer>
      
    </Modal>


      <center><Image src={Logo} width={'100%'} /></center> <br/>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" >
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-900">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h2 className="mb-0" style={{color:'#5F4D9E'}}>Talentum Mujeres 2021</h2>
                  <h5 className="mb-0">Inscripciones cerradas, tendremos los resultados a partir del 12 de Agosto del 2021</h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
