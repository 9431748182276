
import React, { useState, useEffect } from "react";
import { Col, Row,  Button, Modal, Container, Image } from '@themesberg/react-bootstrap';

import Logo from "../../assets/img/pages/Banner-01.jpg";

import { TutorsSelectedTable } from "../../components/Tables";

import { getTutorsByName } from '../../services/tutors';


export default () => {


    const [showHelp, setShowHelp] = useState(false);



    const [tutors, setTutors] = useState([]);


    useEffect( () => {
  
  
      async function fetchData() {
        
        const tutors = await getTutorsByName();
        let arrayTutors = [];
  
        tutors.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          arrayTutors.push({id:doc.id, data: doc.data() });
          console.log(doc.id, " => ", doc.data());
        });
  
        setTutors(arrayTutors);
      }
      fetchData();
  
  
  
  
    }, []);





  return (
    <main  style={{backgroundColor:'#CEC7F2'}}>



    <Modal as={Modal.Dialog} centered show={showHelp} onHide={() => setShowHelp(false)}>
      <Modal.Header>
    
        <Modal.Title className="h2" style={{textAlign: 'center'}}>¿Tienes dudas o algún problema?</Modal.Title>
        
        <Button variant="close" aria-label="Close" onClick={() => setShowHelp(false)} />
      </Modal.Header>
      <Modal.Body>
       
      <p className="mb-0">

<b style={{fontSize:20}}><center>Escríbenos al correo <a  style={{fontSize:22}} target="_blank" href="mailto:talentum@cide.edu">talentum@cide.edu</a></center></b>
</p>


      </Modal.Body>
  
      
    </Modal>


  


           <center><Image src={Logo} width={'100%'} /></center> <br/>

      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>

          <Row className="justify-content-center form-bg-image" >

            <Col xs={12} className="d-flex align-items-center justify-content-center">

              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-900">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h2 className="mb-0" style={{color:'#5F4D9E'}}>Tutores seleccionados para Talentum Mujeres 2021!</h2>
                  <h3 className="mb-0"><b style={{color:'#CD293C'}} >¡Muchas felicidades!</b></h3><br/><br/>

                  <h5 className="mb-0"><b>¿Qué sigue? En breve los organizadores se pondrán en contacto vía correo electrónico.</b></h5><br/><br/>
                
                



                  <TutorsSelectedTable tutors={tutors} />

                 

                </div>
        

               
               {/** <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div> **/}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
