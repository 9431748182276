export const Routes = {

    // pages
   // Presentation: { path: "/" },
    Signin: { path: "/"},
    //register
    TutorsRegister: { path: "/register/tutorsRegister" },
    StudentsRegister: { path: "/register/studentsRegister" },
    DirectTutorsRegister: { path: "/register/directTutorsRegister" },
    DirectStudentsRegister: { path: "/register/directStudentsRegister" },
    //Tutors
    TutorsCandidates: { path: "/dashboard/tutors-candidates"},
    Tutors: { path: "/dashboard/tutors"},

    StudentsCandidates: { path: "/dashboard/students-candidates"},
    Students: { path: "/dashboard/students"},

    Calendar : { path: "/dashboard/calendar"},
    Sessions : { path: "/dashboard/sessions"},
    Session : { path: "/dashboard/session/:sessionId"},
    Resources : { path: "/dashboard/session/resources/:sessionId"},
    TutoringGroups : { path: "/dashboard/tutoring-groups"},
    Tutorship : { path: "/dashboard/tutorship"},
    Notices : { path: "/dashboard/notices"},
    Notice : { path: "/dashboard/notice/:sessionId"},
    Message : { path: "/dashboard/message/:messageId"},
    Dictionary : { path: "/dashboard/dictionary"},
    Word : { path: "/dashboard/word/:wordId"},

    DashboardOverview: { path: "/dashboard/overview" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};