import { createUserWithEmailAndPassword,sendPasswordResetEmail,limit,collection,
        auth,addDoc,getDocs,query,doc,orderBy,setDoc,where,db } from "../../../talentum-mujeres-2021/src/Firebase";




export const registerStudentsCandidate = async (studentsCandidate) => {
   // alert(JSON.stringify(tutorsCandidate));
    const docRef = await addDoc(collection(db, "candidatasAlumnas"),studentsCandidate);
      console.log("Document written with ID: ", docRef.id);
      return docRef;
}

export const studentCandidateStartTest = async (id, startTime,finishTime) => {  
  const candidateRef = doc(db, 'candidatasAlumnas', id);
  setDoc(candidateRef, { startTest: true, startTime,finishTime }, { merge: true }).then((response) => {
    // alert(JSON.stringify("DOCUMENT CREATED "+response));

    
  }).catch(function(error) {
    alert(error.message);
  });

  }


  export const registerTestStudentsCandidate  = async (id,sc) => {  
    //alert(JSON.stringify(sc));
    const candidateRef = doc(db, 'candidatasAlumnas', id);
    setDoc(candidateRef, {q1:sc.q1,q2:sc.q2,q3:sc.q3,q4:sc.q4,q5:sc.q5,q6:sc.q6,q7:sc.q7,q8:sc.q8,
      q9:sc.q9,q10:sc.q10,q11:sc.q11,q12:sc.q12,status:sc.status,finishTest:sc.finishTest,realFinishTime:sc.realFinishTime}, { merge: true }).then((response) => {
      // alert(JSON.stringify("DOCUMENT CREATED "+response));
      
    }).catch(function(error) {
      alert(error.message);
    });
  
    }





  export const getStudentCandidate = async (email) => {
    const querySnapshot = await getDocs(query(collection(db, "candidatasAlumnas"),  where("email", "==", email),limit(1)));
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0];
    } else {
        return null;
    }
  }



  export const addVideoPresentationURL = async (id, url) => {  
    const candidateRef = doc(db, 'candidatasAlumnas', id);
    return setDoc(candidateRef, { video: url, status:"Registrado" }, { merge: true });

}

export const acceptStudentCandidate = async (id) => {  
     const candidateRef = doc(db, 'candidatasAlumnas', id);
     setDoc(candidateRef, { status: 'Aceptado' }, { merge: true });

}


export const createStudent = async (idCandidate,name,email) => {  
  createUserWithEmailAndPassword(auth, email, "AlumnaTalentum2021")
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
     // alert("response user CREATED "+JSON.stringify(user));
     // user.uid
     // const docRef = await addDoc(collection(db, "candidatosTutores"),tutorsCandidate);


     const userRef = doc(db, 'users', user.uid);
     // Analert('userRef '+JSON.stringify(userRef));

     setDoc(userRef, {'role':'student', 'uid':user.uid,'name': name, 'email':user.email, 'studentCandidateId': idCandidate}).then((response) => {
      // alert(JSON.stringify("DOCUMENT CREATED "+response));
      sendPasswordResetEmail(auth,email).then((response) => {
       
        const studentRef = doc(db, 'candidatasAlumnas', idCandidate);
        setDoc(studentRef, { status: 'Inscrita' }, { merge: true });

      }).catch(function(error) {
        alert(error.message);
        // An error happened.
      });
    }).catch(function(error) {
      alert(error.message);
      // An error happened.
    });

     
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });

}




export const refuseStudentCandidate = async (id) => {
  const candidateRef = doc(db, 'candidatasAlumnas', id);
  setDoc(candidateRef, { status: 'Rechazado' }, { merge: true });
}

export const getStudentsCandidate = async (state) => {
  //const querySnapshot = await getDocs(query(collection(db, "candidatasAlumnas"),where("status", "!=", "Deshabilitado") ,orderBy("status", "asc"),orderBy("place","asc"), orderBy("name", "asc")));
  const querySnapshot = await getDocs(query(collection(db, "candidatasAlumnas"),where("status", "not-in", ["Deshabilitado","Aceptado", "Inscrita"]), where("place","==",state) ,orderBy("status", "asc"), orderBy("name", "asc")));
   
     return querySnapshot;
}

export const disableStudentCandidate = async (id) => {
  const candidateRef = doc(db, 'candidatasAlumnas', id);
  setDoc(candidateRef, { status: 'Deshabilitado' }, { merge: true });
}

export const disableStudents = async (listIdsStudents) => {
  listIdsStudents.forEach( id => {
     disableStudentCandidate(id);
  });
  
}

export const getStudents = async (state) => {

  const querySnapshot = await getDocs(query(collection(db, "candidatasAlumnas"), where("status", "in", ['Aceptado','Inscrita']),where("place","==",state), orderBy("score","desc"), orderBy("name", "asc")));
  return querySnapshot;

}


export const addTutor = async (id, tutor) => {
  const candidateRef = doc(db, 'candidatasAlumnas', id);
  setDoc(candidateRef, { tutor: {id:tutor.id,name:tutor.data.name,email:tutor.data.email} }, { merge: true });
}


export const removeTutor = async (id) => {
  const candidateRef = doc(db, 'candidatasAlumnas', id);
  setDoc(candidateRef, { tutor: {} }, { merge: true });
}

export const getStudentsOfTutor = async (tutorId) => {
   const querySnapshot = await getDocs(query(collection(db, "candidatasAlumnas"), 
    where("tutor.id", "==", tutorId)));
    return querySnapshot;
  
}

export const getStudentRegistered = async (email) => {
  const querySnapshot = await getDocs(query(collection(db, "candidatasAlumnas"),  
    where("email", "==", email),
    where("status", "==", 'Inscrita'),
    limit(1)));
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0];
  } else {
      return null;
  }
}

export const getStudentAcepted = async (email) => {
  const querySnapshot = await getDocs(query(collection(db, "candidatasAlumnas"),  
    where("email", "==", email),
    where("status", "==", 'Aceptado'),
    limit(1)));
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0];
  } else {
      return null;
  }
}

export const getStudentsOfTutorByEmail = async (email) => {
  const querySnapshot = await getDocs(query(collection(db, "candidatasAlumnas"), 
   where("tutor.email", "==", email)));
   return querySnapshot;
}


