import { initializeApp } from 'firebase/app';

import { getAuth, signInWithEmailAndPassword,createUserWithEmailAndPassword,sendPasswordResetEmail } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL  } from "firebase/storage";

import { doc,collection, getDoc, getDocs, getFirestore,
    addDoc,setDoc,orderBy,query, where ,limit, arrayUnion  } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyCEtTotM-HyLApzzhyj0A363g8QwfggBOY",
    authDomain: "talentummujerescivitas.firebaseapp.com",
    projectId: "talentummujerescivitas",
    storageBucket: "talentummujerescivitas.appspot.com",
    messagingSenderId: "88328609401",
    appId: "1:88328609401:web:8afac332caaa0eb179fea8",
    measurementId: "G-6VWEV0DWK7"
};



initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const storage = getStorage();



export {storage,ref,getDownloadURL,uploadBytesResumable,getAuth,signInWithEmailAndPassword,
    createUserWithEmailAndPassword,sendPasswordResetEmail,doc,getDocs,collection,query,where,
    limit, getDoc,addDoc,orderBy, setDoc,arrayUnion, auth,db }