import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFileAlt, faEdit, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import {  Card, Form, Button, ListGroup,InputGroup } from '@themesberg/react-bootstrap';

import { getSessions, disableSession, enableSession, addStudentToSession } from '../../services/sessions';

import moment from "moment";




export default () => {

  const user = JSON.parse(sessionStorage.getItem("userLoged"));

  const [sessions, setSessions] = useState([]);
  const [status, setStatus] = useState('Próximas');
  const [title, setTitle] = useState('Próximas');



  useEffect( () => {
    async function fetchData() {
      const sessionsResolve = await getSessions(status,user.role);
      let sessions = [];
      sessionsResolve.forEach((session) => {
 
        sessions.push({id:session.id, data: session.data() });
        console.log(session.id, " => ", session.data());
      });
      setSessions(sessions);
    }
    fetchData();
  }, [status]);


  const changeStatus = (status) => {
    setStatus(status);
    if(status === 'Próximas') setTitle('Próximas');
    if(status === 'Finalizadas') setTitle('Finalizadas');
    if(status === 'Activas') setTitle('Activas');
    if(status === 'Inactivas') setTitle('Inactivas');

  }


  const getMonth = (timestamp) =>{
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    return months[moment(timestamp.toDate(), 'DD-MM-YYYY').format('M')-1];
  }

  const disableEnableSession = async (session) => {
    let sessionUpdateResolve;
    if(session.data.active){
      sessionUpdateResolve = await disableSession(session.id);
    }else{
      sessionUpdateResolve = await enableSession(session.id);
    }

    const sessionsResolve = await getSessions(status, user.role);
    let sessions = [];
    sessionsResolve.forEach((session) => {

      sessions.push({id:session.id, data: session.data() });
      console.log(session.id, " => ", session.data());
    });
    setSessions(sessions);

    
  }

  const invokeAddStudentToSession = async (sessionId,students,url) => {
    if(students === undefined || (students.find(student => student.email === user.email) === undefined)){
      if(user.role === 'student'){
        await addStudentToSession(sessionId, user.email, user.name);
        window.location.replace(url);
      }else{
        window.location.replace(url);
      }
    
    }else{
      window.location.replace(url);
    }
   
  }



  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Sesiones</h4>
         {(user.role === 'super' || user.role === 'admin') &&
          <p className="mb-0">Revisa y configura las sesiones.</p> }
          {(user.role === 'student' || user.role === 'tutor') &&
          <p className="mb-0">Revisa las sesiones en la plataforma.</p> }
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
        {/*
        <ButtonGroup>
         <ExcelFile  filename="Relación de alumnas"
  
  element={ <Button  variant="outline-primary" size="sm">Exportar a excel</Button>}>
                <ExcelSheet data={sessions} name="Candidatas">
                    <ExcelColumn label="Folio" value={(col) => col.id} />
                    <ExcelColumn label="Nombre" value={(col) => col.data.name} />
                    <ExcelColumn label="Estado" value={(col) => col.data.place} />
                    <ExcelColumn label="Edad" value={(col) => col.data.candidateAge} />
                    <ExcelColumn label="Correo" value={(col) => col.data.email} />
                    <ExcelColumn label="Escuela" value={(col) => col.data.school} />
                    <ExcelColumn label="Turno" value={(col) => col.data.schoolShift} />
                    <ExcelColumn label="¿Prueba lógica?" value={(col) => col.data.finishTest?'Sí':'No'} />
                    <ExcelColumn label="¿Video?" value={(col) => col.data.video!=undefined?'Sí':'No'} />
                    <ExcelColumn label="Status" value={(col) => col.data.status} />

                </ExcelSheet>
            </ExcelFile>
          </ButtonGroup> */}
        </div>
      </div>

      <div className="table-settings mb-4">


        { /** 
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Buscar" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>  */}


        <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">{title}</h5>

        <InputGroup style={{width: 350}}>
     
                    <Form.Select   value={status}  onChange={evt => changeStatus(evt.target.value)}>
                      <option>Próximas</option>
                      <option>Finalizadas</option>
                      {(user.role ==='admin' || user.role === 'super') && <option>Activas</option>}
                      {(user.role ==='admin' || user.role === 'super') && <option>Inactivas</option>}
                    </Form.Select>
                   

                    {(user.role ==='admin' || user.role === 'super') && <Button onClick={() => window.location.replace("#/dashboard/session/new") }  variant="outline-primary" size="md"><FontAwesomeIcon icon={faPlus} className="me-2"/>
                       Crear sesión
                    </Button>}
                    </InputGroup> 
      </Card.Header>
      <Card.Body>
        <ListGroup className="list-group-flush list my--3">
          {sessions.map(session => 
          <ListGroup.Item className="px-0">
            <div class="align-items-center d-block d-sm-flex border-bottom border-light pb-4 mb-4 row">
              <div class="col-auto mb-3 mb-sm-0 col">
                <div class="calendar d-flex">
                  <span class="calendar-month">{getMonth(session.data.sessionDate)}</span>
                  <span class="calendar-day">{session.data.sessionDate.toDate().getDate()}</span>
                </div>
              </div>
              <div class="col">
              <span><h6>{session.data.name}</h6></span>
                <span>{session.data.shortDescription}</span>
                <div class="small fw-bold mt-1">Hora: {moment(session.data.sessionDate.toDate()).format('hh:mm A')}</div>
                <span class="small fw-bold">Link: {session.data.url} </span><br/>
                <Button onClick={() => invokeAddStudentToSession(session.id,session.data.students,session.data.url)}  variant="outline-primary" 
                size="sm" style={{float:'left',marginRight:10}} >
                      Entrar a reunión
                    </Button>
              </div>
              {(user.role === 'super' || user.role === 'admin') && <div class="col">
             

            

                    <Button  variant={session.data.active?"outline-danger":"outline-success"} 
                    size="md" style={{float:'right'}} onClick={() => disableEnableSession(session)}>
                <FontAwesomeIcon icon={session.data.active?faTimes:faCheckCircle} className="me-2"/>
                       {session.data.active?'Desactivar':'Activar'}
                    </Button>

                    <Button  onClick={() => window.location.replace("#/dashboard/session/resources/"+session.id) }  
                    variant="outline-primary" size="md" style={{float:'right',marginRight:10}} >
                      <FontAwesomeIcon icon={faFileAlt}/>
                    </Button>
                    <Button  onClick={() => window.location.replace("#/dashboard/session/"+session.id) }  
                    variant="outline-primary" size="md" style={{float:'right',marginRight:10}} >
                <FontAwesomeIcon icon={faEdit}/>
                       
                    </Button>
 
              </div>}


              {(user.role === 'student' || user.role === 'tutor') && <div class="col">
             


                    <Button  onClick={() => window.location.replace("#/dashboard/session/resources/"+session.id) }  
                    variant="outline-primary" size="md" style={{float:'right',marginRight:10}} >
                      <FontAwesomeIcon icon={faFileAlt}/>  Recursos de la sesión
                    </Button>

              </div>}

            </div>
        </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>


      </div>

     
    </>
  );
};

