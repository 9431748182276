  import { collection,addDoc,getDocs,query,doc,getDoc,orderBy,setDoc,where,
           db, arrayUnion } from "../Firebase";

  export const getSessions = async (status,role) => {

    let typeFilter, isActive, isFinished;
 
    if(status === 'Finalizadas') {isFinished = true; typeFilter = "finishNext"}
    if(status === 'Próximas') {isFinished = false; typeFilter = "finishNext"}
    if(status === 'Activas') {isActive= true; typeFilter = "activeInactive";}
    if(status === 'Inactivas') {isActive= false ; typeFilter = "activeInactive";}



    if(role ==='admin' || role === 'super'){
      const querySnapshot = await getDocs(query(collection(db, "sesions"),
      typeFilter === "finishNext"?
        isFinished? where("sessionDate","<", new Date()): where("sessionDate",">", new Date()):
                    where("active","==",isActive),orderBy("sessionDate","asc")));
      return querySnapshot;
    }else{
      const querySnapshot = await getDocs(query(collection(db, "sesions"),
        isFinished? where("sessionDate","<", new Date()): where("sessionDate",">", new Date()),
                    where("active","==",true),orderBy("sessionDate","asc")));
      return querySnapshot;
    }
   
    
    
    

  }

  export const getSession = async (sessionId) => {
    const docRef = await doc(db, 'sesions', sessionId);
     return await getDoc(docRef);
  }

export const disableSession = async (id) => {  
     const sesionRef = doc(db, 'sesions', id);
     const docRef = await setDoc(sesionRef, { active: false}, { merge: true });
     return docRef;
}

export const enableSession = async (id) => {  
  const sesionRef = doc(db, 'sesions', id);
  const docRef = await setDoc(sesionRef, { active: true}, { merge: true });
  return docRef;
}

export const createSession = async (sesion) => {
   const docRef = await addDoc(collection(db, "sesions"),sesion);
     console.log("Document written with ID: ", docRef.id);
     return docRef;
}


export const updateSession = async (id, sesion) => {
  const sesionRef = doc(db, 'sesions', id);
  setDoc(sesionRef, sesion, { merge: true });
}



export const addResourceToSession = async (id, url, name, description, type) => {  
  const candidateRef = doc(db, 'sesions', id);
  return setDoc(candidateRef, { resources: arrayUnion({url, name, description, type})}, { merge: true });

}

export const addStudentToSession = async (sessionId, email, name) => {  
  const candidateRef = doc(db, 'sesions', sessionId);
  return setDoc(candidateRef, { students: arrayUnion({email, name})}, { merge: true });
}