import React, { useState, useEffect } from "react";
import {  Card,Image} from '@themesberg/react-bootstrap';

import { getNotice } from '../../services/notices';

import {useParams} from "react-router-dom";




export default () => {


  const { messageId } = useParams();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [image, setImage] = useState('');
  const [priority, setPriority] = useState('Normal');

  const [showingTo, setShowingTo] = useState('Alumnas');

  const user = JSON.parse(sessionStorage.getItem("userLoged"));



  useEffect( () => {
    async function fetchData() {
       const notice = await getNotice(messageId);
        setTitle(notice.data().title);
        setMessage(notice.data().message);
        setUrl(notice.data().url);
        setImage(notice.data().image);
        setPriority(notice.data().priority);
        setShowingTo(notice.data().showingTo);
    }
    fetchData();
  }, []); 





  return (
    <>


      <div className="table-settings mb-4">
        <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light justify-content-between align-items-center">
      <div class="row">

      <div class="offset-2 col-8 mb-3 mb-sm-0 col">
        <center><h4>{title}</h4></center>
        </div>
        </div>
        
      </Card.Header>
      <Card.Body>
     
      <div class="row">
      <div class="offset-2 col-8 mb-3 mb-sm-0 col">
          {message}
          <br/>
          {url &&
          <a class="card-link" target="_blank" rel="noopener noreferrer" href={url}>
          {url}</a>
          }<br/><br/>
          <Image src={image} />

      </div>

   




      </div>

    
                  
      </Card.Body>
    </Card>


      </div>

     
    </>
  );
};

