
import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faUser, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card,Modal, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup, Form } from '@themesberg/react-bootstrap';


import { acceptTutorCandidate, refuseTutorCandidate, createTutor } from '../services/tutors';

import { disableStudents, acceptStudentCandidate, refuseStudentCandidate, createStudent } from '../services/students';





export const TutorsCandidatesTable = (props) => {
  const totalTransactions = props.tutorsCandidates.length;
  //alert('totalTransactions '+totalTransactions);

  const TableRow = (props) => {

    const [showDefault, setShowDefault] = useState(false);
    const [showAcept, setShowAcept] = useState(false);
    const [showRefuse, setShowRefuse] = useState(false);
    





    const handleClose = () => {
      setShowDefault(false);
    }

  const handleOpen = () => {
    setShowDefault(true);
}

const handleCloseAcept = () => {
  setShowAcept(false);
}

const handleOpenAcept  = () => {
setShowAcept(true);
}

const handleCloseRefuse = () => {
  setShowRefuse(false);
}

const handleOpenRefuse = () => {
setShowRefuse(true);
}

const aceptCandidate = (id) => {
  setShowAcept(false);
  acceptTutorCandidate(id);
  }

const refuseCandidate = (id) => {
  setShowRefuse(false);
  refuseTutorCandidate(id);
  }

    const { id, email,name,sex, place, candidateAge, university, career, yearOfCareer,graduateTime, 
      volunteerExperience, tutorTaleuntumMujeres, numberHoursAvailable,timeAvailable, status,
      haveBrothersOrSisters,haveExperience,internetAccess,kindOfVolunteer,program,
      score,whatDoYouWantToLearn,whyAreInteresting,yourSkills } = props;
    let color = 'red';
    if(score < 50 && score > 0){
      color = 'darkorange';
    }
    if(score > 50 && score <= 80){
      color = '#CCC000';
    }
    if(score > 80){
      color = 'green';
    }

    const statusVariant = name === "Paid" ? "success"
      : name === "Due" ? "warning"
        : name === "Canceled" ? "danger" : "primary";

    return (<>

<Modal as={Modal.Dialog} centered  animation={true} show={showAcept} onHide={handleCloseAcept}>
  <Modal.Header>
          <Modal.Title  style={{textAlign: 'center'}}>¿Quieres aceptar a este candidato?</Modal.Title>
  </Modal.Header> 
  <Modal.Body>
  <b style={{fontSize:18}}>Nombre: {name}  -   Score: {score}</b><br/><br/>
        <p style={{color:'red'}}><b>Atención: </b> Al aceptar a este candidato se agregará al listado de candidatos aceptados que aparecerá el próximo 21 de Julio.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={() => aceptCandidate(id)}>
        Aceptar candidato
    </Button>
      <Button variant="link" className="text-gray ms-auto" onClick={handleCloseAcept}>
        Cancelar
    </Button>
    </Modal.Footer>

</Modal>

<Modal as={Modal.Dialog} centered  animation={true} show={showRefuse} onHide={handleCloseRefuse}>
  <Modal.Header>
          <Modal.Title  style={{textAlign: 'center'}}>¿Quieres rechazar a este candidato?</Modal.Title>
  </Modal.Header>     
  <Modal.Body>
      <p>Nombre: {name}  -   Score: {score}</p><br/><br/>
      <p style={{color:'red'}}><b>Atención: </b> Al rechazar a este candidato se removerá del listado de candidatos aceptados que aparecerá el próximo 21 de Julio.</p>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="danger" onClick={() => refuseCandidate(id)}>
        Rechazar candidato
    </Button>
      <Button variant="link" className="text-gray ms-auto" onClick={handleCloseRefuse}>
        Cancelar
    </Button>
    </Modal.Footer>
</Modal>

<Modal as={Modal.Dialog} centered size="xl" fullscreen='xl-down' animation={true} show={showDefault} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title  style={{textAlign: 'center'}}>Detalle del candidato a tutor</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <center><h5>Folio del registro</h5><b style={{color:'#5F4D9E', fontSize:18}}>{id}</b></center>


        <table style={{width: '100%', marginTop:30}}>
      <tr>
        <td className="border-0"><b>Nombre: </b>  {name} </td>
        <td className="border-0"><b>Email: </b>  {email} </td>
        

        <td className="border-0"><b>Edad: </b>  {candidateAge} años</td>
        <td className="border-0"><b>Sexo: </b> {sex}</td>
       
       
      </tr>
      <tr>
      <td className="border-0"><b>Lugar de residencia: </b> {place}</td>
        <td className="border-0"><b>Universidad: </b>  {university} </td>
        <td className="border-0"><b>Carrera: </b>  {career}</td>
       
       
      </tr>


      <tr>
        <td className="border-0"><b>Año de carrera: </b> {yearOfCareer}</td>
        <td className="border-0"><b>Tiempo de egresado: </b> {graduateTime}</td>
        <td className="border-0"><b>Internet y dispositivo: </b> {internetAccess}</td>
       
      </tr>

      <tr>
        <td className="border-0"><b>Horas a la semana disponibles: </b> {numberHoursAvailable}</td>
        <td className="border-0"><b>Disponibilidad de 5 a 7 pm: </b> {timeAvailable}</td>
        <td className="border-0"><b>Tutor en talentum: </b> {tutorTaleuntumMujeres}</td>
       
      </tr>

      <tr>
        <td className="border-0"><b>Experiencia en programas de liderazgo: </b> {haveExperience}</td>
        <td className="border-0"><b>Nombre del programa: </b> {program}</td>
        
       
      </tr>
      <tr>
      <td className="border-0"><b>Experiencia como voluntario: </b> {volunteerExperience}</td>
      <td className="border-0"><b>Cuentanos sobre tu voluntariado: </b> {kindOfVolunteer}</td>
      </tr>


      <tr>
      <td className="border-0"><b>Tienes hermanos: </b> {haveBrothersOrSisters}</td>
      <td className="border-0"><b>¿Por qué te interesa ser tutor/a del programa? </b> {whyAreInteresting}</td>
      </tr>

      <tr>
      <td className="border-0"><b>¿Qué puedes aportar? </b> {yourSkills}</td>
      <td className="border-0"><b>¿Qué esperas aprender? </b> {whatDoYouWantToLearn}</td>
      </tr>
     

      

    

      

      </table>

      </Modal.Body>
      <Modal.Footer style={{display: 'block'}}>



      </Modal.Footer>
      
    </Modal>

      <tr>
        <td>
          <span className="fw-normal">
              {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <center>{candidateAge}</center>
          </span>
        </td>
        <td>
          <span className="fw-normal">
          <center> {volunteerExperience}</center>
          </span>
        </td>
        <td>
          <span className="fw-normal">
          <center> {tutorTaleuntumMujeres}</center>
          </span>
        </td>
        <td>
          <span className="fw-normal">
           {numberHoursAvailable}
          </span>
        </td>
        <td>
          <span className="fw-normal">
           {timeAvailable}
          </span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            <center><b style={{'color':color, fontSize:16}}>{score}</b></center>
          </span>
        </td>

        <td>
          <span className="fw-normal">
           {status!=null?status:'Nuevo'}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={handleOpen}>
                <FontAwesomeIcon icon={faEye} className="me-2"  /> Ver detalle
              </Dropdown.Item>
              <Dropdown.Item onClick={handleOpenAcept}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Aceptar
              </Dropdown.Item>
             
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      </>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
    {totalTransactions > 0 && <center>
      <h5>Actualmente hay <b style={{color:'#5F4D9E'}}>{totalTransactions}</b> candidatos registrados</h5>
    </center>}

      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Nombre</th>
              <th className="border-bottom">Edad</th>
              <th className="border-bottom">¿Voluntario?</th>
              <th className="border-bottom">¿Talentum?</th>
              <th className="border-bottom">Horas por semana</th>
              <th className="border-bottom">Disponibilidad</th>
              <th className="border-bottom">Score</th>
              <th className="border-bottom">Status</th>
            </tr>
          </thead>
          <tbody>
            {props.tutorsCandidates.map(t => <TableRow key={`transaction-${t.id}`} id={t.id} {...t.data} />)}
          </tbody>
        </Table>
        {/*
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
        */}
      </Card.Body>
    </Card>
  );
};



export const TutorsTable = (props) => {
  const totalTransactions = props.tutors.length;
  //alert('totalTransactions '+totalTransactions);

  const TableRow = (props) => {

    const [showDefault, setShowDefault] = useState(false);
    const [showRegist, setShowRegist] = useState(false);
    const [showRefuse, setShowRefuse] = useState(false);





    const handleClose = () => {
      setShowDefault(false);
    }

  const handleOpen = () => {
    setShowDefault(true);
}

const handleCloseRegist = () => {
  setShowRegist(false);
}

const handleOpenRegist  = () => {
setShowRegist(true);
}

const handleCloseRefuse = () => {
  setShowRefuse(false);
}

const handleOpenRefuse = () => {
setShowRefuse(true);
}

const registCandidate = (id,name,email) => {
  setShowRegist(false);
  //alert('iniciando creacion de tutot');
  createTutor(id,name,email);
  }

const refuseCandidate = (id) => {
  setShowRefuse(false);
  refuseTutorCandidate(id);
  }

    const { id, email,name,sex, place, candidateAge, university, career, yearOfCareer,graduateTime, 
      volunteerExperience, tutorTaleuntumMujeres, numberHoursAvailable,timeAvailable, status,
      haveBrothersOrSisters,haveExperience,internetAccess,kindOfVolunteer,program,
      score,whatDoYouWantToLearn,whyAreInteresting,yourSkills } = props;
    let color = 'red';
    if(score < 50 && score > 0){
      color = 'darkorange';
    }
    if(score > 50 && score <= 80){
      color = '#CCC000';
    }
    if(score > 80){
      color = 'green';
    }

    const statusVariant = name === "Paid" ? "success"
      : name === "Due" ? "warning"
        : name === "Canceled" ? "danger" : "primary";

    return (<>

<Modal as={Modal.Dialog} centered  animation={true} show={showRegist} onHide={handleCloseRegist}>
  <Modal.Header>
          <Modal.Title  style={{textAlign: 'center'}}>¿Quieres registrar a este tutor?</Modal.Title>
  </Modal.Header> 
  <Modal.Body>
  <b style={{fontSize:18}}>Nombre: {name}  -   Score: {score}</b><br/><br/>
        <p style={{color:'red'}}><b>Atención: </b> Al registrar a este tutor se generará una cuenta de usuario y se envíara los datos de acceso al correo asociado al momento del registro.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={() => registCandidate(id,name, email)}>
        Registrar tutor
    </Button>
      <Button variant="link" className="text-gray ms-auto" onClick={handleCloseRegist}>
        Cancelar
    </Button>
    </Modal.Footer>

</Modal>

<Modal as={Modal.Dialog} centered  animation={true} show={showRefuse} onHide={handleCloseRefuse}>
  <Modal.Header>
          <Modal.Title  style={{textAlign: 'center'}}>¿Quieres rechazar a este tutor?</Modal.Title>
  </Modal.Header>     
  <Modal.Body>
      <p>Nombre: {name}  -   Score: {score}</p><br/><br/>
      <p style={{color:'red'}}><b>Atención: </b> Al rechazar a este tutor se removerá del listado de tutores aceptados que aparecerá el próximo 21 de Julio.</p>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="danger" onClick={() => refuseCandidate(id)}>
        Rechazar tutor
    </Button>
      <Button variant="link" className="text-gray ms-auto" onClick={handleCloseRefuse}>
        Cancelar
    </Button>
    </Modal.Footer>
</Modal>

<Modal as={Modal.Dialog} centered size="xl" fullscreen='xl-down' animation={true} show={showDefault} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title  style={{textAlign: 'center'}}>Detalle del tutor</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <center><h5>Folio del registro</h5><b style={{color:'#5F4D9E', fontSize:18}}>{id}</b></center>


        <table style={{width: '100%', marginTop:30}}>
      <tr>
        <td className="border-0"><b>Nombre: </b>  {name} </td>
        <td className="border-0"><b>Email: </b>  {email}</td>
        <td className="border-0"><b>Edad: </b>  {candidateAge} años</td>
        <td className="border-0"><b>Sexo: </b> {sex}</td>
       
       
      </tr>
      <tr>
      <td className="border-0"><b>Lugar de residencia: </b> {place}</td>
        <td className="border-0"><b>Universidad: </b>  {university} </td>
        <td className="border-0"><b>Carrera: </b>  {career}</td>
       
       
      </tr>


      <tr>
        <td className="border-0"><b>Año de carrera: </b> {yearOfCareer}</td>
        <td className="border-0"><b>Tiempo de egresado: </b> {graduateTime}</td>
        <td className="border-0"><b>Internet y dispositivo: </b> {internetAccess}</td>
       
      </tr>

      <tr>
        <td className="border-0"><b>Horas a la semana disponibles: </b> {numberHoursAvailable}</td>
        <td className="border-0"><b>Disponibilidad de 5 a 7 pm: </b> {timeAvailable}</td>
        <td className="border-0"><b>Tutor en talentum: </b> {tutorTaleuntumMujeres}</td>
       
      </tr>

      <tr>
        <td className="border-0"><b>Experiencia en programas de liderazgo: </b> {haveExperience}</td>
        <td className="border-0"><b>Nombre del programa: </b> {program}</td>
        
       
      </tr>

      <tr>
      <td className="border-0"><b>Experiencia como voluntario: </b> {volunteerExperience}</td>
      <td className="border-0"><b>Cuentanos sobre tu voluntariado: </b> {kindOfVolunteer}</td>
      </tr>

      <tr>
      <td className="border-0"><b>Tienes hermanos: </b> {haveBrothersOrSisters}</td>
      <td className="border-0"><b>¿Por qué te interesa ser tutor/a del programa? </b> {whyAreInteresting}</td>
      </tr>

      <tr>
      <td className="border-0"><b>¿Qué puedes aportar? </b> {yourSkills}</td>
      <td className="border-0"><b>¿Qué esperas aprender? </b> {whatDoYouWantToLearn}</td>
      </tr>
     

      

    

      

      </table>

      </Modal.Body>
      <Modal.Footer style={{display: 'block'}}>



      </Modal.Footer>
      
    </Modal>

      <tr>
        <td>
          <span className="fw-normal">
              {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <center>{candidateAge}</center>
          </span>
        </td>
        <td>
          <span className="fw-normal">
          <center> {volunteerExperience}</center>
          </span>
        </td>
        <td>
          <span className="fw-normal">
          <center> {tutorTaleuntumMujeres}</center>
          </span>
        </td>
        <td>
          <span className="fw-normal">
           {numberHoursAvailable}
          </span>
        </td>
        <td>
          <span className="fw-normal">
           {timeAvailable}
          </span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            <center><b style={{'color':color, fontSize:16}}>{score}</b></center>
          </span>
        </td>

        <td>
          <span className="fw-normal">
           {status!=null?status:'Nuevo'}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={handleOpen}>
                <FontAwesomeIcon icon={faEye} className="me-2"  /> Ver detalle
              </Dropdown.Item>
              {status!="Registrado" && <>
              <Dropdown.Item  onClick={handleOpenRegist}>
                <FontAwesomeIcon icon={faUser} className="me-2"  /> Registrar
              </Dropdown.Item>
              

            
              <Dropdown.Item  onClick={handleOpenRefuse} className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Rechazar
              </Dropdown.Item>
            </>}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      </>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">

{totalTransactions == 1 && <center>
      <h5>Actualmente hay <b style={{color:'#5F4D9E'}}>{totalTransactions}</b> tutor aceptado</h5>
    </center>}

{totalTransactions > 1 && <center>
      <h5>Actualmente hay <b style={{color:'#5F4D9E'}}>{totalTransactions}</b> tutores aceptado</h5>
    </center>}

      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Nombre</th>
              <th className="border-bottom">Edad</th>
              <th className="border-bottom">¿Voluntario?</th>
              <th className="border-bottom">¿Talentum?</th>
              <th className="border-bottom">Horas por semana</th>
              <th className="border-bottom">Disponibilidad</th>
              <th className="border-bottom">Score</th>
              <th className="border-bottom">Status</th>
            </tr>
          </thead>
          <tbody>
            {props.tutors.map(t => <TableRow key={`transaction-${t.id}`} id={t.id} {...t.data} />)}
          </tbody>
        </Table>
       {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer> */}
      </Card.Body>
    </Card>
  );
};







export const TutorsSelectedTable = (props) => {
  const totalTransactions = props.tutors.length;
  //alert('totalTransactions '+totalTransactions);

  const TableRow = (props) => {

    const { id, email,name,sex, place, candidateAge, university, career, yearOfCareer,graduateTime, 
      volunteerExperience, tutorTaleuntumMujeres, numberHoursAvailable,timeAvailable, status,
      haveBrothersOrSisters,haveExperience,internetAccess,kindOfVolunteer,program,
      score,whatDoYouWantToLearn,whyAreInteresting,yourSkills } = props;



    return (<>

      <tr>
        <td>
          <span className="fw-normal">
              {name}
          </span>
        </td>
    
      </tr>
      </>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">


      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Nombre completo del tutor</th>
            </tr>
          </thead>
          <tbody>
            {props.tutors.map(t => <TableRow key={`transaction-${t.id}`} id={t.id} {...t.data} />)}
          </tbody>
        </Table>
       {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer> */}
      </Card.Body>
    </Card>
  );
};








export const StudentsCandidatesTable = (props) => {
  const totalTransactions = props.tutorsCandidates.length;
  const place = props.place;

  const [studentsSelected, setStudentsSelected] = useState([]);
  const [studentSelected, setStudentSelected] = useState({});
  const [idStudentSelected, setIdStudentSelected] = useState('');
  



  props.tutorsCandidates.forEach(student => {
    student.isSelected = false;
  });


  function handleCheck(selected,id){
    let students = [];
   
   students = studentsSelected;

   // alert(id +' selected '+ selected);
    if(selected){
      students.push(id);
    
    }else{
     // alert('delete id '+id);

     for( var i = 0; i < students.length; i++){ 
    
      if ( students[i] === id) { 
  
        students.splice(i, 1); 
      }
     

     
  }

 
      //students = students.filter(item => item !== id);
      
     
    }
  
    setStudentsSelected([...students]);
   

  

   
  }
 






    const [showDefault, setShowDefault] = useState(false);
    const [showAcept, setShowAcept] = useState(false);
    const [showRefuse, setShowRefuse] = useState(false);




    const handleClose = () => {
      setShowDefault(false);
    }

  const handleOpen = (student) => {
    setStudentSelected(student.data);
    setIdStudentSelected(student.id);
    setShowDefault(true);
}

const handleCloseAcept = () => {
  setShowAcept(false);
}

const handleOpenAcept  = () => {
setShowAcept(true);
}

const handleCloseRefuse = () => {
  setShowRefuse(false);
}

const handleOpenRefuse = () => {
setShowRefuse(true);
}

const aceptCandidate = () => {
  acceptStudentCandidate(idStudentSelected);
  setShowAcept(false);
  }

const refuseCandidate = (id) => {
  setShowRefuse(false);
  refuseTutorCandidate(id);
  }


  

    const { id,name } = props;


    const correctAnswers = (student) => {

      let correctAnswers = 0;
  
      if(student.q1 === '8'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q2 === '4'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q3 === '5'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q4 === '1'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q5 === '2'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q6 === '5'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q7 === '6'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q8 === '3'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q9 === '7'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q10 === '8'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q11 === '7'){
        correctAnswers = correctAnswers+1;
      }
      if(student.q12 === '6'){
        correctAnswers = correctAnswers+1;
      }

      return correctAnswers;


    }


    const score = (correctAnswers) => {
      return  (correctAnswers * (10/12)).toFixed(1);
    }

    const color = (score) => {  
    let color = 'red';
    if(score < 6 && score > 5){
      color = 'darkorange';
    }
    if(score >= 6 && score < 8){
      color = '#CCC000';
    }
    if(score >= 8){
      color = 'green';
    }

    return color;
    }


    const disabledStudents = () =>{

      disableStudents(studentsSelected);
      alert('Se han deshabilitado '+studentsSelected.length+ ' registros');

    }

    

  

   

    
    


    

    const statusVariant = name === "Paid" ? "success"
      : name === "Due" ? "warning"
        : name === "Canceled" ? "danger" : "primary";

 



  return (<div>



<Modal as={Modal.Dialog} centered  animation={true} show={showAcept} onHide={handleCloseAcept}>
  <Modal.Header>
          <Modal.Title  style={{textAlign: 'center'}}>¿Quieres aceptar a esta candidata?</Modal.Title>
  </Modal.Header> 
  <Modal.Body>
  <b style={{fontSize:18}}>Nombre: {studentSelected.name}  -   Score: {studentSelected.score}</b><br/><br/>
        <p style={{color:'red'}}><b>Atención: </b> Al aceptar a este candidato se agregará al listado de candidatas aceptadas que aparecerá el próximo 12 de Agosto.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={() => aceptCandidate()}>
        Aceptar candidato
    </Button>
      <Button variant="link" className="text-gray ms-auto" onClick={handleCloseAcept}>
        Cancelar
    </Button>
    </Modal.Footer>

</Modal>

<Modal as={Modal.Dialog} centered  animation={true} show={showRefuse} onHide={handleCloseRefuse}>
  <Modal.Header>
          <Modal.Title  style={{textAlign: 'center'}}>¿Quieres rechazar a este candidato?</Modal.Title>
  </Modal.Header>     
  <Modal.Body>
      <p>Nombre: {name}  -   Score: {score}</p><br/><br/>
      <p style={{color:'red'}}><b>Atención: </b> Al rechazar a este candidato se removerá del listado de candidatos aceptados que aparecerá el próximo 21 de Julio.</p>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="danger" onClick={() => refuseCandidate(id)}>
        Rechazar candidato
    </Button>
      <Button variant="link" className="text-gray ms-auto" onClick={handleCloseRefuse}>
        Cancelar
    </Button>
    </Modal.Footer>
</Modal>

<Modal as={Modal.Dialog} centered size="xl" fullscreen='xl-down' animation={true} show={showDefault} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title  style={{textAlign: 'center'}}>Detalle del candidato a tutor</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <center><h5>Folio del registro</h5><b style={{color:'#5F4D9E', fontSize:18}}>{id}</b></center>


        <table style={{width: '100%', marginTop:30}}>
      <tr>
        <td className="border-0"><b>Nombre: </b>  {studentSelected.name} </td>
        <td className="border-0"><b>Email: </b>  {studentSelected.email} </td>
        

        <td className="border-0"><b>Edad: </b>  {studentSelected.candidateAge} años</td>
       
        <td className="border-0"><b>Lugar de residencia: </b> {studentSelected.place}</td>
      </tr>
      <tr>
    
        <td className="border-0"><b>Escuela: </b>  {studentSelected.school} </td>
        <td className="border-0"><b>Turno: </b>  {studentSelected.schoolShift}</td>
        <td className="border-0"><b>Tipo: </b>  {studentSelected.typeSchool}</td>
       
       
      </tr>


      <tr>
      <td className="border-0"><b># Aciertos: </b> {correctAnswers(studentSelected)}</td>
      <td className="border-0"><b>Calificación: </b> {score(correctAnswers(studentSelected))}</td>
        
      </tr>

      <tr>
      <td className="border-0"><b>Comprobante de estudios: </b>  
        <a href={studentSelected.documentSchool} target="_blank" rel="noopener noreferrer">Ver documento</a>
        </td>
        {studentSelected.video &&
        <td className="border-0"><b>Video presentación: </b> 
        <a href={studentSelected.video} target="_blank" rel="noopener noreferrer" >Ver video</a> </td>
        }
       

      </tr>

      

      </table>

      </Modal.Body>
      <Modal.Footer style={{display: 'block'}}>



      </Modal.Footer>
      
    </Modal>


<div>

{totalTransactions > 0 && <center>
      <h5>Hay <b style={{color:'#5F4D9E'}}>{totalTransactions}</b> candidatas registradas en {place}</h5>
    </center>}


    <Card border="light" className="table-wrapper table-responsive shadow-sm" >
    
  

      <Card.Body className="pt-0">
     
        <Table hover className="user-table align-items-center" style={{overflowX:'auto'}}>
          <thead>
            <tr>
            {/**  <th className="border-bottom">

              <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
        <span className="icon icon-sm">
          ({studentsSelected.length}) Seleccionados
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item className="text-danger" onClick={() => disabledStudents()}>
                 Deshabilitar
       </Dropdown.Item>
       
      </Dropdown.Menu>
    </Dropdown>
              
                
              </th> */}
              <th className="border-bottom">Correo</th>
              <th className="border-bottom">Nombre</th>
              <th className="border-bottom">Edad</th>
              <th className="border-bottom">¿Video?</th>
              <th className="border-bottom">Score</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {props.tutorsCandidates.map(student=> 



              <tr>

<td>
    <span className="fw-normal">
       {/* <Form.Check id="checkbox1" htmlFor="checkbox1" 
        onChange={(evt) => handleCheck(evt.target.checked, student.id)} /> */}

         { student.data.email }
    </span>
  </td>

  <td>
    <span className="fw-normal">
        {/* student.data.email */}
        {student.data.name}
    </span>
  </td>
  <td>
    <span className="fw-normal">
      <center>{student.data.candidateAge}</center>
    </span>
  </td>

  <td>
    <span className="fw-normal">
     {student.data.video?'Sí':'No'}
    </span>
  </td>


  <td>
    <span className={`fw-normal text-${statusVariant}`}>
      <center><b style={{'color':color(score(correctAnswers(student.data))), fontSize:16}}>{score(correctAnswers(student.data))}</b></center>
    </span>
  </td>

  <td>
    <span className="fw-normal">
     {student.data.status!=null?student.data.status:'Nuevo'}
    </span>
  </td>
  <td>
    <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
        <span className="icon icon-sm">
          <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item  onClick={() => handleOpen(student)}>
          <FontAwesomeIcon icon={faEye} className="me-2"  /> Ver detalle
        </Dropdown.Item>
        <Dropdown.Item onClick={handleOpenAcept}>
          <FontAwesomeIcon icon={faEdit} className="me-2" /> Aceptar
        </Dropdown.Item>
       
      </Dropdown.Menu>
    </Dropdown>
  </td>
</tr>

            
          
            
            
            
            
            
            
            
            )}
          </tbody>
        </Table>
        
      </Card.Body>
    </Card>
    </div>
    </div>);

}

  {/* 
    <TableRow key={`transaction-${t.id}`} id={t.id} {...t.data}  parentCallback = {handleCallback} />
  */}







  export const StudentsTable = (props) => {
    const totalTransactions = props.students.length;
    const state = props.state;
    //alert('totalTransactions '+totalTransactions);
  
    const TableRow = (props) => {
  
      const [showDefault, setShowDefault] = useState(false);
      const [showRegist, setShowRegist] = useState(false);
      const [showRefuse, setShowRefuse] = useState(false);
  
  
  
  
  
      const handleClose = () => {
        setShowDefault(false);
      }
  
    const handleOpen = () => {
      setShowDefault(true);
  }
  
  const handleCloseRegist = () => {
    setShowRegist(false);
  }
  
  const handleOpenRegist  = () => {
  setShowRegist(true);
  }
  
  const handleCloseRefuse = () => {
    setShowRefuse(false);
  }
  
  const handleOpenRefuse = () => {
  setShowRefuse(true);
  }
  
  const registCandidate = (id,name,email) => {
    setShowRegist(false);
    //alert('iniciando creacion de tutot');
    createStudent(id,name,email);
    }
  
  const refuseCandidate = (id) => {
    setShowRefuse(false);
    refuseStudentCandidate(id);
    }
  
      const { id, email,name, school, place, schoolShift,candidateAge, video, 
        documentSchool, typeSchool,score, status } = props;
      let color = 'red';
      if(score < 50 && score > 0){
        color = 'darkorange';
      }
      if(score > 50 && score <= 80){
        color = '#CCC000';
      }
      if(score > 80){
        color = 'green';
      }
  
      const statusVariant = name === "Paid" ? "success"
        : name === "Due" ? "warning"
          : name === "Canceled" ? "danger" : "primary";
  
      return (<>
  
  <Modal as={Modal.Dialog} centered  animation={true} show={showRegist} onHide={handleCloseRegist}>
    <Modal.Header>
            <Modal.Title  style={{textAlign: 'center'}}>¿Quieres registrar a esta alumna?</Modal.Title>
    </Modal.Header> 
    <Modal.Body>
    <b style={{fontSize:18}}>Nombre: {name}</b><br/>
    <b style={{fontSize:18}}>Correo: {email}</b>
    <br/> <br/>
          <p style={{color:'red'}}><b>Atención: </b> Al registrar a esta alumna se generará una cuenta de usuario y se envíara los datos de acceso al correo asociado al momento del registro.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => registCandidate(id,name, email)}>
          Registrar alumna
      </Button>
        <Button variant="link" className="text-gray ms-auto" onClick={handleCloseRegist}>
          Cancelar
      </Button>
      </Modal.Footer>
  
  </Modal>
  
  <Modal as={Modal.Dialog} centered  animation={true} show={showRefuse} onHide={handleCloseRefuse}>
    <Modal.Header>
            <Modal.Title  style={{textAlign: 'center'}}>¿Quieres rechazar a esta alumna?</Modal.Title>
    </Modal.Header>     
    <Modal.Body>
    <b style={{fontSize:18}}>Nombre: {name}</b><br/>
    <b style={{fontSize:18}}>Correo: {email}</b>
        <br/> <br/>
        <p style={{color:'red'}}><b>Atención: </b> Se removerá del listado de candidatas aceptadas que aparecerá el próximo 12 de Agosto.</p>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="danger" onClick={() => refuseCandidate(id)}>
          Rechazar alumna
      </Button>
        <Button variant="link" className="text-gray ms-auto" onClick={handleCloseRefuse}>
          Cancelar
      </Button>
      </Modal.Footer>
  </Modal>
  
  <Modal as={Modal.Dialog} centered size="xl" fullscreen='xl-down' animation={true} show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title  style={{textAlign: 'center'}}>Detalle del tutor</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <center><h5>Folio del registro</h5><b style={{color:'#5F4D9E', fontSize:18}}>{id}</b></center>
  
  
          <table style={{width: '100%', marginTop:30}}>
        <tr>
          <td className="border-0"><b>Nombre: </b>  {name} </td>
          <td className="border-0"><b>Email: </b>  {email}</td>
          
  
         
         
        </tr>

        <tr>
          <td className="border-0"><b>Edad: </b>  {candidateAge} años</td>
          <td className="border-0"><b>Lugar de residencia: </b> {place}</td>
        </tr>

        <tr>
       
          <td className="border-0"><b>Escuela: </b>  {school} </td>
          <td className="border-0"><b>Turno: </b>  {schoolShift}</td>
          <td className="border-0"><b>Tipo: </b>  {typeSchool}</td>
         
         
        </tr>


        <tr>
      <td className="border-0"><b>Comprobante de estudios: </b>  
        <a href={documentSchool} target="_blank" rel="noopener noreferrer">Ver documento</a>
        </td>
        {video &&
        <td className="border-0"><b>Video presentación: </b> 
        <a href={video} target="_blank" rel="noopener noreferrer" >Ver video</a> </td>
        }
       

      </tr>
  
  
        </table>
  
        </Modal.Body>
        <Modal.Footer style={{display: 'block'}}>
  
  
  
        </Modal.Footer>
        
      </Modal>
  
        <tr>
          

          <td>
            <span className="fw-normal">
            <center> {email}</center>
            </span>
          </td>

          <td>
            <span className="fw-normal">
                {name}
            </span>
          </td>

          <td>
            <span className="fw-normal">
              <center>{candidateAge}</center>
            </span>
          </td>
         
          <td>
            <span className="fw-normal">
            <center> {video?'Sí':'No'}</center>
            </span>
          </td>
      
          
         
  
          <td>
            <span className="fw-normal">
             {status!=null?status:'Nuevo'}
            </span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item  onClick={handleOpen}>
                  <FontAwesomeIcon icon={faEye} className="me-2"  /> Ver detalle
                </Dropdown.Item>
                {status!="Inscrita" && <>
                <Dropdown.Item  onClick={handleOpenRegist}>
                  <FontAwesomeIcon icon={faUser} className="me-2"  /> Inscribir
                </Dropdown.Item>
                
  
              
                <Dropdown.Item  onClick={handleOpenRefuse} className="text-danger">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Rechazar
                </Dropdown.Item>
              </>}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
        </>
      );
    };
  
    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
  
  {totalTransactions == 1 && <center>
        <h5>Actualmente hay <b style={{color:'#5F4D9E'}}>{totalTransactions}</b> alumna aceptada</h5>
      </center>}
  
  {totalTransactions > 1 && <center>
        <h5>Actualmente hay <b style={{color:'#5F4D9E'}}>{totalTransactions}</b> alumnas aceptadas</h5>
      </center>}
  
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Correo</th>
                <th className="border-bottom">Nombre</th>
                <th className="border-bottom">Edad</th>
                <th className="border-bottom">¿Video?</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {props.students.map(t => <TableRow key={`transaction-${t.id}`} id={t.id} {...t.data} />)}
            </tbody>
          </Table>
         {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev>
                  Previous
                </Pagination.Prev>
                <Pagination.Item active>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Item>5</Pagination.Item>
                <Pagination.Next>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{totalTransactions}</b> out of <b>25</b> entries
            </small>
          </Card.Footer> */}
        </Card.Body>
      </Card>
    );
  };





export const TutorsAvailablesTable = (props) => {
  const totalTransactions = props.tutors.length;
  //alert('totalTransactions '+totalTransactions);

  const TableRow = (props) => {

    const { id, email,name, tutor } = props;

     function selectTutor(tutor){

      props.parentCallback(tutor);
    }

  



    return (<>

      <tr>
        <td>
          <span className="fw-normal">
              {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
             <Button onClick={() => selectTutor(tutor)} >Seleccionar</Button>
          </span>
        </td>
    
      </tr>
      </>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm" style={{height:300}}>


      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Tutores ({props.tutors.length})</th>
              <th className="border-bottom"></th>
            </tr>
          </thead>
          <tbody>
            {props.tutors.map(t => <TableRow key={`transaction-${t.id}`} id={t.id} tutor={t}  {...t.data} parentCallback={props.parentCallback} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};


export const StudentsAvailablesTable = (props) => {
  const totalTransactions = props.students.length;
  //alert('totalTransactions '+totalTransactions);

  const TableRow = (props) => {

    const { id, email,name } = props;

    function addTutor(id, tutor){
      props.parentCallback(id,tutor);
    }



    return (<>

      <tr>
        <td>
          <span className="fw-normal">
              {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
          {props.tutorSelected.id===undefined?
             <Button disabled >Agregar</Button>:
             <Button  onClick={() => addTutor(id,props.tutorSelected)} >Agregar</Button>
          }
          </span>
        </td>
    
      </tr>
      </>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm" style={{height:300}}>


      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Alumnas disponibles en {props.place} ({props.students.length})</th>
              <th className="border-bottom"></th>
            </tr>
          </thead>
          <tbody>
            {props.students.map(t => <TableRow key={`transaction-${t.id}`} id={t.id} {...t.data} 
            parentCallback={props.parentCallback} tutorSelected={props.tutorSelected}  />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};


export const StudentsInGroupAvailablesTable = (props) => {
  const totalTransactions = props.students.length;
  //alert('totalTransactions '+totalTransactions);

  const TableRow = (props) => {

    const { id, email,name } = props;


    function removeTutor(id){
      props.parentCallback(id);
    }



    return (<>

      <tr>
        <td>
          <span className="fw-normal">
              {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
              {email}
          </span>
        </td>
        <td>
          <span className="fw-normal">
             <Button onClick={() => removeTutor(id)}>Quitar</Button>
          </span>
        </td>
    
      </tr>
      </>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm" style={{height:300}}>


      <Card.Body className="pt-0">
        <Table hover className=" align-items-center">
          <thead>
            <tr>
              <th className="border-bottom"> Tutor <b>{props.tutorSelected.data.name}</b> ({props.students.length})</th>
              <th className="border-bottom">Correo</th>
              <th className="border-bottom"></th>
            </tr>
          </thead>
          <tbody>
            {props.students.map(t => <TableRow key={`transaction-${t.id}`} id={t.id} {...t.data} parentCallback={props.parentCallback} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};


export const StudentsInGroupTable = (props) => {
  const totalTransactions = props.students.length;
  //alert('totalTransactions '+totalTransactions);

  const TableRow = (props) => {

    const { id, email,name } = props;


    function removeTutor(id){
      props.parentCallback(id);
    }



    return (<>

      <tr>
        <td>
          <span className="fw-normal">
              {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
              {email}
          </span>
        </td>
    
      </tr>
      </>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm" style={{height:500}}>


      <Card.Body className="pt-0"><br/>
        <h5> Tutor/Tutora: {props.tutorSelected.data!==undefined?props.tutorSelected.data.name:''}
        ({props.students.length})</h5>
        <Table hover className=" align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Nombre completo</th>
              <th className="border-bottom">Correo</th>
            </tr>
          </thead>
          <tbody>
            {props.students.map(t => <TableRow key={`transaction-${t.id}`} id={t.id} {...t.data} parentCallback={props.parentCallback} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};