import React, { useState, useEffect, usest } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTextHeight, faCheckCircle, faCog, faEdit, faEye, faHome, faPlus, faTimes, faFont } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, ButtonGroup, ListGroup, Image,InputGroup, Dropdown } from '@themesberg/react-bootstrap';

import { createNotice, updateNotice, getNotice } from '../../services/notices';
import {storage,ref,uploadBytesResumable,getDownloadURL } from "../../Firebase";


import {useParams} from "react-router-dom";




export default () => {


  const { sessionId } = useParams();

  const [validated, setValidated] = useState(false);
  const [validate, setValidate] = useState(false);


  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [priority, setPriority] = useState('Normal');

  const [file, setFile] = useState();
  const [uploadValue,setUploadValue] = useState(0);
  const [urlValid, setUrlValid] = useState(true);
  const [showingTo, setShowingTo] = useState('Alumnas');

  const titlePage = sessionId !== 'new'?'Editar mensaje':'Crear mensaje';
  const user = JSON.parse(sessionStorage.getItem("userLoged"));



  useEffect( () => {

    async function fetchData() {
      if(sessionId !== 'new'){
       const notice = await getNotice(sessionId);
        setTitle(notice.data().title);
        setMessage(notice.data().message);
        setUrl(notice.data().url);
        setPriority(notice.data().priority);
        setShowingTo(notice.data().showingTo);
      }
      
   
    }
    fetchData();
  }, []); 



 

  const invokeCreateSession =  (event) => {
    

  
    event.preventDefault();

    setValidated(true);

    if(url !== ''){

      if(!validURL(url)){
        //alert('invalid url')
        setUrlValid(false);
        setValidated(false);
       
        return null;
      }else{
        setUrlValid(true);
      }
    }else{
      setUrlValid(true);
    }

    const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    return null;
  }

  

  if(file && file.name){
    var storageRef = ref(storage,`messagesResources/${file.name}`);

    const task = uploadBytesResumable(storageRef,file);

    task.on("state_changed", function(snapshot) {
      var percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
      console.log(percent + "% done");
      setUploadValue(parseInt(percent));
      
      }, null, () => {


        getDownloadURL(storageRef).then((downloadURL) => {
         

          const messageObj = {active:true, created:new Date(), message:message, url:url,
            image:downloadURL,showingTo:showingTo,priority:priority, 
            showingTutors: showingTo === 'Ambos'|| showingTo === 'Tutores'?true:false,
            showingStudents:showingTo === 'Ambos'|| showingTo === 'Alumnas'?true:false, 
            title:title, createdBy:{email: user.email , name:user.name}};
            if(sessionId === 'new'){
              createNotice(messageObj).then((response) => {
                window.location.replace("#/dashboard/notices");
              });
            }else{
              updateNotice(sessionId,messageObj).then((response) => {
                window.location.replace("#/dashboard/notices");
              });
            }



         
        });
      });


  }else{

    const messageObj = {active:true, created:new Date(), message:message, url:url,
      showingTo:showingTo,priority:priority, 
      showingTutors: showingTo === 'Ambos'|| showingTo === 'Tutores'?true:false,
      showingStudents:showingTo === 'Ambos'|| showingTo === 'Alumnas'?true:false, 
      title:title, createdBy:{email: user.email , name:user.name}};
      if(sessionId === 'new'){
        createNotice(messageObj).then((response) => {
          window.location.replace("#/dashboard/notices");
        });
      }else{
        updateNotice(sessionId,messageObj).then((response) => {
          window.location.replace("#/dashboard/notices");
        });
      }


  }

 



    
  }


  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
     return !!pattern.test(str);
  }
  



  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
       
      </div>

      <div className="table-settings mb-4">
        <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">{titlePage}</h5>

        
      </Card.Header>
      <Card.Body>
      <Form noValidate validated={validated} className="mt-4" onSubmit={invokeCreateSession}>
      <div class="row">
      <div class="col-4 mb-3 mb-sm-0 col">
      <Form.Group id="title" className="mb-4">
                <Form.Label>Titulo</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={title} 
                  onChange={evt => setTitle(evt.target.value)} autoFocus required
                  placeholder="Titulo del mensaje" />
                </InputGroup>
              </Form.Group>
      </div>

      <div class="col-4 mb-3 mb-sm-0 col" >
      <Form.Group id="shortDescription" className="mb-4" >
                <Form.Label>Mensaje</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
  
                  </InputGroup.Text>
                  <Form.Control  
                  style={{'resize': 'none'}}
                  as="textarea" rows="3" size="60" value={message} 
                  onChange={evt => setMessage(evt.target.value)} autoFocus required
                  placeholder="Mensaje que deseas comunicar" />
                </InputGroup>
              </Form.Group>
      </div>

      <div class="col-4 mb-3 mb-sm-0 col" >
      <Form.Group id="url" className="mb-4" >
                <Form.Label>Url</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control
                   type="text" size="60" value={url} 
                   isInvalid={!urlValid}
                   isValid={urlValid}
                  onChange={evt => setUrl(evt.target.value)} 
                  placeholder="Url del mensaje" />
                                  <Form.Control.Feedback type="invalid">La url debe iniciar con http:// o https://</Form.Control.Feedback>

                </InputGroup>
              </Form.Group>
      </div>




   




      </div>

      <div class="row">


      <div class="col-4 mb-3 mb-sm-0 col" >
      <Form.Group id="imageMessage" className="mb-4">
                    <Form.Label>Imagen del mensaje</Form.Label>
                    <Form.Control type="file"   onChange={(evt) => setFile(evt.target.files[0])}/>


                   

                  </Form.Group>
      </div>


      <div class="col-4 mb-3 mb-sm-0 col" >
      <Form.Group id="typeSchool" className="mb-4">
                    <Form.Label>Prioridad</Form.Label>
                    <InputGroup>
                        <Form.Select  value={priority}  onChange={evt => setPriority(evt.target.value)}>
                            <option  defaultValue value=''>Selecciona una opción</option>
                            <option>Normal</option>
                            <option>Baja</option>
                            <option>Alta</option>
                        </Form.Select>
                    </InputGroup>
                  </Form.Group>
                  </div>
     

               
                  <div class="col-4 mb-3 mb-sm-0 col" >
                  <Form.Group id="showing" className="mb-4">
                    <Form.Label>Dirigido a</Form.Label>
                    <InputGroup>
                        <Form.Select  value={showingTo} isValid={showingTo !=='' && validate} 
                          isInvalid={showingTo ==='' && validate} required 
                          onChange={evt => setShowingTo(evt.target.value)}>
                            <option  defaultValue value=''>Selecciona una opción</option>
                            <option>Alumnas</option>
                            <option>Tutores</option>
                            <option>Ambos</option>
                        </Form.Select>
                    </InputGroup>
                  </Form.Group>
          </div>


          <div class="offset-4 col-4 mb-3 mb-sm-0 col" >
      
      

                  {uploadValue === 0 ? <Button style={{marginTop:31,width:300}} variant="primary" type="submit" className="w-100">
                      {titlePage}
                  </Button>:   <center style={{marginTop:31}}><h3>{uploadValue>0&&uploadValue<100?'Subiendo ... ' + uploadValue+'%':''}</h3></center> }

              
                
                  </div> 


          </div>
                  
        </Form>
      </Card.Body>
    </Card>


      </div>

     
    </>
  );
};

