import React, { useState, useEffect } from "react";

import { Col, Row, Form,InputGroup } from '@themesberg/react-bootstrap';

import ReactExport from "react-export-excel";

import { TutorsAvailablesTable, StudentsAvailablesTable, StudentsInGroupAvailablesTable } from "../../components/Tables";

import { getStudents, addTutor, removeTutor, getStudentsOfTutor  } from '../../services/students';
import { getTutors } from '../../services/tutors';




export default () => {


  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [tutors, setTutors] = useState([]);
  const [tutorSelected, setTutorSelected] = useState({});
  const [students, setStudents] = useState([]);
  const [studentsOfTutor, setStudentsOfTutor] = useState([]);
  const [place, setPlace] = useState('Aguascalientes');
  const [refresh, setRefresh] = useState(false);


  useEffect( () => {


    async function fetchData() {

      const tutors = await getTutors();
      let arrayTutors = [];

      tutors.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        arrayTutors.push({id:doc.id, data: doc.data() });
        console.log(doc.id, " => ", doc.data());
      });

      setTutors(arrayTutors);

      const students = await getStudents(place);
      let arrayStudents= [];

      students.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        if(doc.data().tutor === undefined || doc.data().tutor.id === undefined ){
          arrayStudents.push({id:doc.id, data: doc.data() });
        }
       
        console.log(doc.id, " => ", doc.data());
      });

      setStudents(arrayStudents);

    }
    fetchData();




  }, [place, refresh]);

  const handleCallback = async (childData) => {
    setTutorSelected(childData);
    const studentsOfTutor = await getStudentsOfTutor(childData.id);


    let arrayStudents= [];

    studentsOfTutor.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      arrayStudents.push({id:doc.id, data: doc.data() });
      console.log(doc.id, " => ", doc.data());
    });

    setStudentsOfTutor(arrayStudents);


    
}

const removeTutorFromStudent=(studentId) =>{
  removeTutor(studentId).then(() =>{
    setRefresh(!refresh);
    handleCallback(tutorSelected);
  });
 
}

const addTutorToStudent = (studentId, tutor) =>{
  addTutor(studentId, tutor).then(() =>{
    setRefresh(!refresh);
    handleCallback(tutorSelected);
  });
}


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Grupo de tutoría</h4>
          <p className="mb-0">Revisa y crea los grupos de tutoría</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
      {/*  <ButtonGroup>
         <ExcelFile  filename="Relación de alumnas"
  
  element={ <Button  variant="outline-primary" size="sm">Exportar a excel</Button>}>
                <ExcelSheet data={tutors} name="Candidatas">
                    <ExcelColumn label="Folio" value={(col) => col.id} />
                    <ExcelColumn label="Nombre" value={(col) => col.data.name} />
                    <ExcelColumn label="Estado" value={(col) => col.data.place} />
                    <ExcelColumn label="Edad" value={(col) => col.data.candidateAge} />
                    <ExcelColumn label="Correo" value={(col) => col.data.email} />
                    <ExcelColumn label="Escuela" value={(col) => col.data.school} />
                    <ExcelColumn label="Turno" value={(col) => col.data.schoolShift} />
                    <ExcelColumn label="¿Prueba lógica?" value={(col) => col.data.finishTest?'Sí':'No'} />
                    <ExcelColumn label="¿Video?" value={(col) => col.data.video!=undefined?'Sí':'No'} />
                    <ExcelColumn label="Status" value={(col) => col.data.status} />

                </ExcelSheet>
            </ExcelFile>
          </ButtonGroup> */}
        </div>
      </div>

      <div className="table-settings mb-4 offset-6">

      <Form.Group id="place" className="mb-4" style={{marginLeft:12}}>
                    <Form.Label>Filtrar alumnas por estado:</Form.Label>
                    <InputGroup style={{width: 300}}>
                    <Form.Select   value={place}  onChange={evt => setPlace(evt.target.value)}>
                        <option defaultValue value={''}>Selecciona una opción</option>
                        <option>Aguascalientes</option>
                        <option>Baja California</option>
                        <option>Baja California Sur</option>
                        <option>Campeche</option>
                        <option>Chiapas</option>
                        <option>Chihuahua</option>
                        <option>Ciudad de México</option>
                        <option>Coahuila</option>
                        <option>Colima</option>
                        <option>Durango</option>
                        <option>Estado de México</option>
                        <option>Guanajuato</option>
                        <option>Guerrero</option>
                        <option>Hidalgo</option>
                        <option>Jalisco</option>
                        <option>Michoacán</option>
                        <option>Morelos</option>
                        <option>Nayarit</option>
                        <option>Nuevo León</option>
                        <option>Oaxaca</option>
                        <option>Puebla</option>
                        <option>Querétaro</option>
                        <option>Quintana Roo</option>
                        <option>San Luis Potosí</option>
                        <option>Sinaloa</option>
                        <option>Sonora</option>
                        <option>Tabasco</option>
                        <option>Tamaulipas</option>
                        <option>Tlaxcala</option>
                        <option>Veracruz</option>
                        <option>Yucatán</option>
                        <option>Zacatecas</option>
                    </Form.Select>
                    </InputGroup>
                  </Form.Group>

        { /** 
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Buscar" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>  */}
      </div>
      <Row className="justify-content-between">
      <Col xs={12} md={6} lg={6} xl={6}>
        <TutorsAvailablesTable tutors={tutors} parentCallback = {handleCallback} />
      </Col>
      <Col xs={12} md={6} lg={6} xl={6}>
        <StudentsAvailablesTable students={students}  place={place} tutorSelected={tutorSelected} 
        parentCallback={addTutorToStudent} />
      </Col>

{tutorSelected.data !== undefined &&
      <Col xs={12} md={12} lg={12} xl={12} style={{marginTop:25}}>
        <StudentsInGroupAvailablesTable students={studentsOfTutor} 
        tutorSelected={tutorSelected} parentCallback = {removeTutorFromStudent} />
      </Col>}

      

      
      </Row>
    </>
  );
};
