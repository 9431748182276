import { collection,addDoc,getDocs,query,doc,getDoc,orderBy,setDoc,where, db } from "../Firebase";




  export const getWords= async (status) => {
   

    let isActive;
 

    if(status === 'Activas') isActive= true;
    if(status === 'Inactivas') isActive= false;
   
    
    
    const querySnapshot = await getDocs(query(collection(db, "dictionary"),
                  where("active","==",isActive),
                  orderBy("created","desc")));
    return querySnapshot;

  }

  export const getWord = async (sessionId) => {
    const docRef = await doc(db, 'dictionary', sessionId);
     return await getDoc(docRef);
  }

export const disableWord = async (id) => {  
     const sesionRef = doc(db, 'dictionary', id);
     const docRef = await setDoc(sesionRef, { active: false}, { merge: true });
     return docRef;
}

export const enableWord= async (id) => {  
  const sesionRef = doc(db, 'dictionary', id);
  const docRef = await setDoc(sesionRef, { active: true}, { merge: true });
  return docRef;
}

export const createWord = async (sesion) => {
   const docRef = await addDoc(collection(db, "dictionary"),sesion);
     console.log("Document written with ID: ", docRef.id);
     return docRef;
}


export const updateWord= async (id, sesion) => {
  const sesionRef = doc(db, 'dictionary', id);
  setDoc(sesionRef, sesion, { merge: true });
}


