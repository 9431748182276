import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont } from '@fortawesome/free-solid-svg-icons';
import { Card, Form, Button,InputGroup } from '@themesberg/react-bootstrap';



import { createWord, updateWord, getWord } from '../../services/dictionary';
import {useParams} from "react-router-dom";
const user = JSON.parse(sessionStorage.getItem("userLoged"));




export default () => {


  const { wordId } = useParams();

  const [validated, setValidated] = useState(false);
  const [validate, setValidate] = useState(false);

  const [title, setTitle] = useState(wordId !== 'new'?'Editar palabra':'Crear palabra');

  const [word, setWord] = useState('');
  const [definition, setDefinition] = useState('');

  useEffect( () => {

    async function fetchData() {
      if(wordId !== 'new'){
       const word = await getWord(wordId);
        setWord(word.data().word);
        setDefinition(word.data().definition);
      }
      
   
    }
    fetchData();
  }, []); 

 

  const invokeCreateSession =  (event) => {
    
    event.preventDefault();

    setValidated(true);
    const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    return null;
  }

  const wordObj = {active:true, created:new Date(), word:word, 
    definition:definition, createdBy:{email: user.email , name:user.name}};
  if(wordId === 'new'){
    createWord(wordObj).then((sessionRef) => {
      window.location.replace("#/dashboard/dictionary")

    });
  }else{
    updateWord(wordId,wordObj).then((sessionRef) => {
      window.location.replace("#/dashboard/dictionary")

    });
  }
    
  }



  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
       
      </div>

      <div className="table-settings mb-4">
        <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">{title}</h5>

        
      </Card.Header>
      <Card.Body>
      <Form noValidate validated={validated} className="mt-4" onSubmit={invokeCreateSession}>
      <div class="row">
      <div class="col-4 mb-3 mb-sm-0 col">
      <Form.Group id="title" className="mb-4">
                <Form.Label>Palabra</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={word} 
                  onChange={evt => setWord(evt.target.value)} autoFocus required
                  placeholder="Palabra a definir" />
                </InputGroup>
              </Form.Group>
      </div>

      <div class="col-4 mb-3 mb-sm-0 col" >
      <Form.Group id="shortDescription" className="mb-4" >
                <Form.Label>Definición</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={definition} 
                  onChange={evt => setDefinition(evt.target.value)} autoFocus required
                  placeholder="Definición de la palabra" />
                </InputGroup>
              </Form.Group>
      </div>

      <div class="col-4 mb-3 mb-sm-0 col" >
      <Button variant="primary" type="submit"  style={{width:300, marginTop:32}} >
       {title}
                  </Button>
                  </div>



   
      </div>

        </Form>
      </Card.Body>
    </Card>


      </div>

     
    </>
  );
};

