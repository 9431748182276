import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont } from '@fortawesome/free-solid-svg-icons';
import { Card, Form, Button, ListGroup,InputGroup } from '@themesberg/react-bootstrap';

import { createSession, updateSession, getSession } from '../../services/sessions';

import Datetime from "react-datetime";
import moment from "moment-timezone";
import {useParams} from "react-router-dom";




export default () => {


  const { sessionId } = useParams();

  const [validated, setValidated] = useState(false);
  const [validate, setValidate] = useState(false);
  const [title, setTitle] = useState(sessionId !== 'new'?'Editar sesión':'Crear sesión');

  const [name, setName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [type, setType] = useState('');
  const [url, setUrl] = useState('');
  const [sessionDate, setSessionDate] = useState('');





  useEffect( () => {

    async function fetchData() {
      if(sessionId !== 'new'){
       const session = await getSession(sessionId);
        setName(session.data().name);
        setShortDescription(session.data().shortDescription);
        setUrl(session.data().url);
        setType(session.data().type);
        setSessionDate(session.data().sessionDate);

      }
      
   
    }
    fetchData();
  }, []); 

  const getMonthByDate = (date) =>{
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    return months[moment(date, 'DD-MM-YYYY').format('M')-1];
  }

  const invokeCreateSession =  (event) => {
    
    event.preventDefault();

    setValidated(true);
    const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    return null;
  }

  const session = {active:true, createdDate:new Date(), name:name,
  sessionDate:sessionDate.toDate(), shortDescription: shortDescription,
  type:type, url:url};
  if(sessionId === 'new'){
    createSession(session).then((sessionRef) => {
      window.location.replace("#/dashboard/sessions")

    });
  }else{
    updateSession(sessionId,session).then((sessionRef) => {
      window.location.replace("#/dashboard/sessions")

    });
  }
    
  }



  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
       
      </div>

      <div className="table-settings mb-4">
        <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">{title}</h5>

        
      </Card.Header>
      <Card.Body>
      <Form noValidate validated={validated} className="mt-4" onSubmit={invokeCreateSession}>
      <div class="row">
      <div class="col-4 mb-3 mb-sm-0 col">
      <Form.Group id="title" className="mb-4">
                <Form.Label>Titulo</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={name} 
                  onChange={evt => setName(evt.target.value)} autoFocus required
                  placeholder="Nombre de la sesión" />
                </InputGroup>
              </Form.Group>
      </div>

      <div class="col-4 mb-3 mb-sm-0 col" >
      <Form.Group id="shortDescription" className="mb-4" >
                <Form.Label>Descripción breve</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={shortDescription} 
                  onChange={evt => setShortDescription(evt.target.value)} autoFocus required
                  placeholder="Conferencista y/o persona que imparte el taller" />
                </InputGroup>
              </Form.Group>
      </div>



      <div class="col-4 mb-3 mb-sm-0 col" >
      <Form.Group id="url" className="mb-4" >
                <Form.Label>Url de la sesión</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={url} 
                  onChange={evt => setUrl(evt.target.value)} autoFocus required
                  placeholder="Url de la reunión de zoom, google meet u otra plataforma de video conferencias." />
                </InputGroup>
              </Form.Group>
      </div>
      </div>

      <div class="row">
      <div class="col-4 mb-3 mb-sm-0 col" >
      <Form.Group id="typeSchool" className="mb-4">
                    <Form.Label>Tipo de sesión</Form.Label>
                    <InputGroup>
                        <Form.Select  value={type} isValid={type!=='' && validate} isInvalid={type=='' && validate} required onChange={evt => setType(evt.target.value)}>
                            <option  defaultValue value=''>Selecciona una opción</option>
                            <option>Plenaria</option>
                            <option>Tutoría</option>
                            <option>Reto Talentum</option>
                        </Form.Select>
                    </InputGroup>
                  </Form.Group>
                  </div>

               
                  <div class="col-4 mb-3 mb-sm-0 col" >
                  <Form.Group id="sessionDate" className="mb-4" >
                  
                    <Form.Label>Fecha</Form.Label>
                    <Datetime 
          timeFormat={true}
          closeOnSelect={false}
          onChange={setSessionDate}
          renderInput={(props, openCalendar) => (
            <InputGroup>
            
              
              <Form.Control
                required
                type="text"
                value={sessionDate ? moment(sessionDate.toDate()).format("DD/MM/YYYY hh:mm") : ""}
                placeholder="dd/mm/yyyy hh:mm"
                onFocus={openCalendar}
                onChange={() => { }} />
            </InputGroup>
          )} />
          </Form.Group>
          </div>

          </div>
                  
           


           
        <ListGroup className="list-group-flush list my--3">
      

         
          <ListGroup.Item className="px-0">
            <div class="align-items-center d-block d-sm-flex border-top border-light pt-4 mt-4 row">
            <span><h5>Vista previa</h5></span>
              <div class="col-auto mb-3 mb-sm-0 col">
              <div class="calendar d-flex" >
                  <span class="calendar-month">{sessionDate?getMonthByDate(sessionDate):getMonthByDate(new Date())}</span>
                  <span class="calendar-day">{sessionDate?sessionDate.toDate().getDate():new Date().getDate()}</span>
                </div>
                
              </div>
              <div class="col">
              <span><h6>{name}</h6></span>
                <span>{shortDescription}</span>
                <div class="small fw-bold mt-1">Hora: {sessionDate?moment(sessionDate.toDate()).format('hh:mm A'):moment(new Date()).format('hh:mm A')}</div>
                <span class="small fw-bold">Link: <a class="card-link" href={url}>{url}</a></span>
              </div>
             
            </div>
        </ListGroup.Item>
       
        <div class="align-items-center d-block d-sm-flex border-top border-light pt-4 mt-4 row">
        <center>
      
        <Button variant="primary" type="submit"  style={{width:300}} >
       {title}
                  </Button>
              
                  </center>
                  </div> 
       
          
        </ListGroup>
        </Form>
      </Card.Body>
    </Card>


      </div>

     
    </>
  );
};

