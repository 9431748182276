import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont } from '@fortawesome/free-solid-svg-icons';
import { Card, Form, Button, ListGroup,InputGroup } from '@themesberg/react-bootstrap';


import { getSession, addResourceToSession, addStudentToSession } from '../../services/sessions';

import {useParams} from "react-router-dom";

import {storage,ref,uploadBytesResumable,getDownloadURL } from "../../Firebase";





export default () => {

  const user = JSON.parse(sessionStorage.getItem("userLoged"));

  const { sessionId } = useParams();

  const [validated, setValidated] = useState(false);
  const [validate, setValidate] = useState(false);
  const [title, setTitle] = useState('Recursos de la sesión');

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [type, setType] = useState('Propio');
  const [file, setFile] = useState();
  const [uploadValue,setUploadValue] = useState(0);
  const [resources, setResources] = useState([]);
  const [students, setStudents ] = useState([]);


  useEffect( () => {

    async function fetchData() {
      const session = await getSession(sessionId);
      setResources(session.data().resources!==undefined?session.data().resources:[]);
      setStudents(session.data().students!==undefined?session.data().students:[]);
    }
    fetchData();
  }, []); 

  const invokeAddStudentToSession = async () => {
    if(students.find(student => student.email === user.email) === undefined){
      if(user.role === 'student'){
      await addStudentToSession(sessionId, user.email, user.name);
      const studentsArr = students;
      studentsArr.push({email:user.email,name:user.name});
      setStudents(studentsArr);
      }
    }
  }


  const invokeCreateResource = async (event) => {
    
    event.preventDefault();

    setValidated(true);
    const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    return null;
  }


  if(type === 'Propio'){
  var storageRef = ref(storage,`sessionResources/${sessionId}/${file.name}`);

      const task = uploadBytesResumable(storageRef,file);

      task.on("state_changed", function(snapshot) {
        var percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        console.log(percent + "% done");
        setUploadValue(parseInt(percent));
        
        }, null, () => {


          getDownloadURL(storageRef).then((downloadURL) => {
           // alert(downloadURL);
           addResourceToSession(sessionId,downloadURL, name,description, type).then(async () => {
              setUploadValue(0);
              setFile(null);
              setName('');
              setValidated(false);
              const session = await getSession(sessionId);
              setResources(session.data().resources);
            })
           
          });
        });
      }
      
      if(type === 'Externo'){
        addResourceToSession(sessionId,url, name,description, type).then(async () => {
          setUrl('');
          setName('');
          setValidated(false);
          const session = await getSession(sessionId);
          setResources(session.data().resources);
        })

      }

    
  }



  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
       
      </div>

      <div className="table-settings mb-4">
        <Card border="light" className="shadow-sm">
        {(user.role === 'super' || user.role === 'admin') &&
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">{title}</h5>
      </Card.Header>}
      <Card.Body>
      <Form noValidate validated={validated} className="mt-4" onSubmit={invokeCreateResource}>
      
      {(user.role === 'super' || user.role === 'admin') &&
        <div class="row">

      <div class="col-3 mb-3 mb-sm-0 col">
      <Form.Group id="title" className="mb-4">
                <Form.Label>Tipo</Form.Label>
      <Form.Select   value={type}  onChange={evt => setType(evt.target.value)}>
                    <option>Propio</option>
                      <option>Externo</option>
                    </Form.Select>
                    </Form.Group>
      </div>

      <div class="col-3 mb-3 mb-sm-0 col">
      <Form.Group id="title" className="mb-4">
                <Form.Label>Titulo</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={name} 
                  onChange={evt => setName(evt.target.value)} autoFocus required
                  placeholder="Nombre del recurso" />
                </InputGroup>
              </Form.Group>
      </div>



      <div class="col-3 mb-3 mb-sm-0 col">
      <Form.Group id="title" className="mb-4">
                <Form.Label>Descripción</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={description} 
                  onChange={evt => setDescription(evt.target.value)} autoFocus required
                  placeholder="Instrucciones sobre el recurso" />
                </InputGroup>
              </Form.Group>
      </div>

     



      {type === 'Externo' && <div class="col-3 mb-3 mb-sm-0 col" >
      <Form.Group id="url" className="mb-4" >
                <Form.Label>URL del recurso</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFont} />
                  </InputGroup.Text>
                  <Form.Control  type="text" size="60" value={url} 
                  onChange={evt => setUrl(evt.target.value)} autoFocus required
                  placeholder="Url de encuesta, video o sitio de interés" />
                </InputGroup>
              </Form.Group>
      </div>}

      {type === 'Propio' && <div class="col-3 mb-3 mb-sm-0 col" >
      <Form.Group id="documentSchool" className="mb-4">
                    <Form.Label>Archivo del recurso</Form.Label>
                    <Form.Control type="file" required  onChange={(evt) => setFile(evt.target.files[0])}/>


                   

                  </Form.Group>
      </div>}

        <div class="offset-4 col-4 mb-4 mb-sm-0 col" >


         {type === 'Externo' &&  <Button variant="primary" type="submit" className="w-100"  style={{marginTop:31}}>Agregar recurso</Button>}

          {type === 'Propio' && uploadValue === 0 ? <Button style={{marginTop:31}} variant="primary" type="submit" className="w-100">
                    Subir recurso
                  </Button>:   <center style={{marginTop:31}}><h3>{uploadValue>0&&uploadValue<100?'Subiendo ... ' + uploadValue+'%':''}</h3></center> }

        </div>
      </div>}




        <ListGroup className="list-group-flush list my--3"><br/>
        <div class="align-items-center d-block d-sm-flex border-bottom border-light pb-4 mb-4 row">
          <h5 className="mb-0">Listado de recursos ({resources!==undefined?resources.length:0})</h5><br/>
        </div>
          {resources.map((resource, index) => 
          <ListGroup.Item className="px-0">
            <div class="align-items-center d-block d-sm-flex border-bottom border-light pb-4 mb-4 row">
              <div class="col">
              <span><h6>{'Recurso #'+(index+1)}

              </h6></span>
               
                <div class="fw-bold mt-1">
                  {resource.description!==undefined?resource.description:''}
                </div>
                <span>
                <a class="card-link" target="_blank" rel="noopener noreferrer" onClick={() => invokeAddStudentToSession()}
                href={resource.url}> {resource.name}</a>
                </span>
                
                {(user.role === 'super' || user.role === 'admin') &&
                <span class="fw-bold">
                {resource.type}
                </span>}
              </div>
              

              
            </div>
        </ListGroup.Item>
          )}
        </ListGroup>

        </Form>
      </Card.Body>
    </Card>


      </div>



     

     
    </>
  );
};

