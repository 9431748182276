import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages

import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";


// Register pages
import TutorsRegister from "./register/TutorsRegister";
import StudentsRegister from "./register/StudentsRegister";

import DirectStudentsRegister from "./register/DirectStudentsRegister";
import DirectTutorsRegister from "./register/DirectTutorsRegister";


//Dasboard

import Calendar from "./dashboard/Calendar";
import Sessions from "./dashboard/Sessions";
import Session from "./dashboard/Session";
import Resources from "./dashboard/Resources";
import TutoringGroups from "./dashboard/TutoringGroups";
import Tutorship from "./dashboard/Tutorship";
import Notices from "./dashboard/Notices";
import Notice from "./dashboard/Notice";
import Message from "./dashboard/Message";
import Dictionary from "./dashboard/Dictionary";
import Word from "./dashboard/Word";

import TutorsCandidates from "./dashboard/TutorsCandidates";
import StudentsCandidates from "./dashboard/StudentsCandidates";
import Tutors from "./dashboard/Tutors";
import Students from "./dashboard/Students";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";






const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (

    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} />  <Component {...props} /></> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [userLoged, setUserLogged] = useState( JSON.parse(sessionStorage.getItem("userLoged")));



  //alert(JSON.stringify(authUser));

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    //alert(JSON.parse(sessionStorage.getItem("userLoged")));
    setUserLogged(JSON.parse(sessionStorage.getItem("userLoged")));

    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
      <Route {...rest} render={props => (
         userLoged != null ?
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content">
            <Navbar />
           
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>: <Signin/>  
      )}
      />

  );
};



export default () => (



  <Switch>
  
   {/**  <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} /> **/}
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* Register */}
    <RouteWithLoader  exact path={Routes.StudentsRegister.path} component={StudentsRegister} />
    <RouteWithLoader  exact path={Routes.TutorsRegister.path} component={TutorsRegister} />

    <RouteWithLoader  exact path={Routes.DirectStudentsRegister.path} component={DirectStudentsRegister} />
    <RouteWithLoader  exact path={Routes.DirectTutorsRegister.path} component={DirectTutorsRegister} />

    {/* Dashboard */}


    <RouteWithSidebar exact path={Routes.Calendar.path} component={Calendar} />
    <RouteWithSidebar exact path={Routes.Sessions.path} component={Sessions} />
    <RouteWithSidebar exact path={Routes.Session.path} component={Session} />
    <RouteWithSidebar exact path={Routes.Resources.path} component={Resources} />
    <RouteWithSidebar exact path={Routes.TutoringGroups.path} component={TutoringGroups} />
    <RouteWithSidebar exact path={Routes.Tutorship.path} component={Tutorship} />
    <RouteWithSidebar exact path={Routes.Notices.path} component={Notices} />
    <RouteWithSidebar exact path={Routes.Message.path} component={Message} />
    <RouteWithSidebar exact path={Routes.Notice.path} component={Notice} />
    <RouteWithSidebar exact path={Routes.Dictionary.path} component={Dictionary} />
    <RouteWithSidebar exact path={Routes.Word.path} component={Word} />

    
    <RouteWithSidebar exact path={Routes.TutorsCandidates.path} component={TutorsCandidates} />
    <RouteWithSidebar exact path={Routes.Tutors.path} component={Tutors} />
    <RouteWithSidebar exact path={Routes.StudentsCandidates.path} component={StudentsCandidates} />
    <RouteWithSidebar exact path={Routes.Students.path} component={Students} />
    

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
