import React, { useState, useEffect, usest } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCheckCircle, faEnvelopeOpenText, faEdit, faEye, faHome, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card,Badge, Form, Button, ButtonGroup, ListGroup, Image,InputGroup, Dropdown } from '@themesberg/react-bootstrap';

import ReactExport from "react-export-excel";

import { StudentsTable } from "../../components/Tables";

import { getNotices, disableNotice ,enableNotice, markAsOpened} from '../../services/notices';
import moment from "moment";




export default () => {

  const user = JSON.parse(sessionStorage.getItem("userLoged"));


  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [sessions, setSessions] = useState([]);
  const [status, setStatus] = useState('Activos');
  const [priority, setPriority] = useState('Todos');
  const [title, setTitle] = useState('Mensajes activos');



  useEffect( () => {
    async function fetchData() {
      const sessionsResolve = await getNotices(status, priority);
      let sessions = [];
      sessionsResolve.forEach((session) => {

        if(user.messages === undefined) {
          session.isOpened = false;
        }else{
          let messageFound = user.messages.find(message => {
           // alert('message '+ message +' === session.id '+ session.id +' result '+  (message === session.id))
            return message === session.id});
         
           
          if(messageFound){
            session.isOpened = true;
          }else{
            session.isOpened = false;
          }
          
        }

        if(session.data().priority === 'Alta'){
          if(user.role === 'student' &&  session.data().showingStudents){
           sessions.unshift({id:session.id, data:{...session.data(),isOpened:session.isOpened}}); 
          }else if(user.role === 'tutor' &&  session.data().showingTutors){
            sessions.unshift({id:session.id, data:{...session.data(),isOpened:session.isOpened}}); 
          }else if(user.role === 'super' || user.role === 'admin'){
            sessions.unshift({id:session.id, data:{...session.data(),isOpened:session.isOpened}}); 
          }

          
        }else{
          if(user.role === 'student' &&  session.data().showingStudents){
            sessions.push({id:session.id, data:{...session.data(),isOpened:session.isOpened}}); 
           }else if(user.role === 'tutor' &&  session.data().showingTutors){
             sessions.push({id:session.id, data:{...session.data(),isOpened:session.isOpened}}); 
           }else if(user.role === 'super' || user.role === 'admin'){
             sessions.push({id:session.id, data:{...session.data(),isOpened:session.isOpened}}); 
           }
        }  
      });
      setSessions(sessions);
    }
    fetchData();
  }, [priority,status]);


  const changeStatus = (status) => {
    setStatus(status);
    if(status === 'Activas') setTitle('Mensajes activos');
    if(status === 'Inactivas') setTitle('Mensajes inactivos');

  }


  const getMonth = (timestamp) =>{
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    return months[moment(timestamp.toDate(), 'DD-MM-YYYY').format('M')-1];
  }

  const disableEnableSession = async (session) => {
    let sessionUpdateResolve;
    if(session.data.active){
      sessionUpdateResolve = await disableNotice(session.id);
    }else{
      sessionUpdateResolve = await enableNotice(session.id);
    }
    
    const sessionsResolve = await getNotices(status, priority);
    let sessions = [];
    sessionsResolve.forEach((session) => {

      if(session.data().priority === 'Alta'){
        //alert('unshift');
        sessions.unshift({id:session.id, data: session.data()}); 
      }else{
        sessions.push({id:session.id, data: session.data()});
        
      }  
      //console.log(session.id, " => ", session.data());
    });
    setSessions(sessions);

    
  }

  const openMessage = async (messageId, isOpened) => {
    if(!isOpened){
      markAsOpened(user.id,messageId).then((response) =>{
        let messages = [];
        if(user.messages !== undefined){
          messages = user.messages;
        }
        messages.push(messageId);
        user.messages = messages;
         sessionStorage.setItem("userLoged",JSON.stringify(user));

        window.location.replace("#/dashboard/message/"+messageId);
      });
    }else{
      window.location.replace("#/dashboard/message/"+messageId);
    }
    
    
  }




  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      {(user.role==='admin' || user.role==='super') &&
        <div className="d-block mb-4 mb-md-0">
          <h4>Pizarrón para mensajes</h4>
        
          <p className="mb-0">Crea y envía comunicados dentro de la plataforma.</p>
        
        </div>}

        {(user.role==='student' || user.role==='tutor') &&
        <div className="d-block mb-4 mb-md-0">
          <h4>Pizarrón de mensajes</h4>
          <p className="mb-0">Revisa los comunicados dentro de la plataforma.</p>
        
        </div>}

        <div className="btn-toolbar mb-2 mb-md-0">
       {/* <ButtonGroup>
         <ExcelFile  filename="Relación de alumnas"
  
  element={ <Button  variant="outline-primary" size="sm">Exportar a excel</Button>}>
                <ExcelSheet data={sessions} name="Candidatas">
                    <ExcelColumn label="Folio" value={(col) => col.id} />
                    <ExcelColumn label="Nombre" value={(col) => col.data.name} />
                    <ExcelColumn label="Estado" value={(col) => col.data.place} />
                    <ExcelColumn label="Edad" value={(col) => col.data.candidateAge} />
                    <ExcelColumn label="Correo" value={(col) => col.data.email} />
                    <ExcelColumn label="Escuela" value={(col) => col.data.school} />
                    <ExcelColumn label="Turno" value={(col) => col.data.schoolShift} />
                    <ExcelColumn label="¿Prueba lógica?" value={(col) => col.data.finishTest?'Sí':'No'} />
                    <ExcelColumn label="¿Video?" value={(col) => col.data.video!=undefined?'Sí':'No'} />
                    <ExcelColumn label="Status" value={(col) => col.data.status} />

                </ExcelSheet>
            </ExcelFile>
          </ButtonGroup> */}
        </div>
      </div>

      <div className="table-settings mb-4">

      

       


        <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">{title}</h5>


        <InputGroup style={(user.role ==='super' || user.role === 'admin')?{width: 500}:{width: 200}}>
        {(user.role === 'tutor' || user.role === 'student')?<Form.Label>Prioridad de los mensajes</Form.Label>:''}
        <Form.Select   value={priority}  onChange={evt => setPriority(evt.target.value)}>
                      <option>Todos</option>
                      <option>Normal</option>
                      <option>Baja</option>
                      <option>Alta</option>
                    </Form.Select>

     
                   {(user.role ==='super' || user.role === 'admin') && <>
                   <Form.Select   value={status}  onChange={evt => changeStatus(evt.target.value)}>
                      <option>Activos</option>
                      <option>Inactivos</option>
                    </Form.Select>
                   

                    <Button onClick={() => window.location.replace("#/dashboard/notice/new") }  variant="outline-primary" size="md"><FontAwesomeIcon icon={faEnvelope} className="me-2"/>
                       Crear mensaje
                    </Button></>}
                    </InputGroup> 
      </Card.Header>
      <Card.Body>
        <ListGroup className="list-group-flush list my--3">
          {sessions.map(session => 
          <ListGroup.Item className="px-0">
            <div class="align-items-center d-block d-sm-flex border-bottom border-light pb-4 mb-4 row">
              <div class="col">
              <span><h6>{session.data.title+' '}
             {(user.role === 'tutor' || user.role === 'student') && !session.data.isOpened &&<Badge pill bg={'quaternary'} text={"white"} className="badge-md notification-count" 
            style={{fontSize:12}}>
            {"Mensaje nuevo"}</Badge>}

              </h6></span>
                <span>{session.data.message}</span>
                <div class="small fw-bold mt-1">Creado: {moment(session.data.created.toDate()).format('DD/MM/yyyy hh:mm A')}</div>
                <span class="small fw-bold">Prioridad: {session.data.priority}</span>
              </div>
              {(user.role ==='super' || user.role === 'admin') &&
              <div class="col">
                    <Button  variant={session.data.active?"outline-danger":"outline-success"} 
                    size="md" style={{float:'right'}} onClick={() => disableEnableSession(session)}>
                <FontAwesomeIcon icon={session.data.active?faTimes:faCheckCircle} className="me-2"/>
                       {session.data.active?'Desactivar':'Activar'}
                    </Button>

                    <Button  variant="outline-primary" size="md" 
                    onClick={() => window.location.replace("#/dashboard/notice/"+session.id)}
                    style={{float:'right',marginRight:10}} >
                <FontAwesomeIcon icon={faEdit}/>
                       
                    </Button>

                   
              </div>}

              {(user.role === 'student' || user.role === 'tutor') &&
                  <div class="col">
                    <Button  variant="outline-primary" 
                    onClick={() => openMessage(session.id, session.data.isOpened)}
                    size="md" style={{float:'right',marginRight:10}} >
                      <FontAwesomeIcon icon={faEnvelopeOpenText}/> Abrir mensaje
                       
                    </Button>
                    </div>
                  }
            </div>
        </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>


      </div>

     
    </>
  );
};

