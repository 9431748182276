
import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Image } from '@themesberg/react-bootstrap';

import Logo from "../../assets/img/pages/Banner-01.jpg";



import { login } from '../../services/users';




export default () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const loginUser = () => {
    login(email, password);

  } 

  return (
    <main>
            <center><Image src={Logo} width={'80%'} /></center> <br/>

      <section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
        <Container>
          <Row className="justify-content-center " >
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-700">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Ingresa a Talentum Mujeres Civitas 2021</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Tu correo electrónico</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control value={email} onChange={evt => setEmail(evt.target.value)} autoFocus required type="email" placeholder="correo@dominio.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Tu contraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control value={password} onChange={evt => setPassword(evt.target.value)} required type="password" placeholder="Contraseña" />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Recordar me</FormCheck.Label>
                      </Form.Check>
                       <Card.Link   onClick={() => window.location.href="#/forgot-password"}
                      className="small text-end">¿Olvidaste tu contraseña?</Card.Link> 
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="button" onClick={loginUser} className="w-100">
                    Ingresar
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
