import {  getAuth, signInWithEmailAndPassword,sendPasswordResetEmail,collection,addDoc,getDoc,getDocs,query,doc,
          orderBy,setDoc, where, limit, db } from "../Firebase";



export const registerStudent = async (student) => {
   // alert(JSON.stringify(tutorsCandidate));
    const docRef = await addDoc(collection(db, "users"),student);
      console.log("Document written with ID: ", docRef.id);
      return docRef;
}

export const registerTutor= async (student) => {
     // alert(JSON.stringify(tutorsCandidate));
      const docRef = await addDoc(collection(db, "users"),student);
        console.log("Document written with ID: ", docRef.id);
        return docRef;
  }


  export const login = async (email, password) => {
     // alert(JSON.stringify(tutorsCandidate));

     const auth = getAuth();
     signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
     // Signed in
     const user = userCredential.user;
    
     const docRef = doc(db, 'users', user.uid);
     const docSnap = await getDoc(docRef);

     if (docSnap.exists()) {
     // alert("Document data:", docSnap.data());
       await sessionStorage.setItem("userLoged",JSON.stringify({...docSnap.data(),id:docSnap.id}));

       
      window.location.href="/#/dashboard/calendar";

    } else {
     // alert("No such document!");

      sessionStorage.setItem("userLoged",null);
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

     }).catch((error) => {
       alert(error.message);
      //alert( error.message);
     const errorCode = error.code;
     const errorMessage = error.message;
     });
  }

  export const sendMessageToRecoveryPassword = async (email) => {

    const auth = getAuth();
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        Promise.resolve(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('errorCode => '+errorCode+' errorMessage => '+ errorMessage);
        Promise.resolve(false);
      });
  
  
  }
  

export const acceptTutorCandidate = async (id) => {  
     const candidateRef = doc(db, 'candidatosTutores', id);
     setDoc(candidateRef, { status: 'Aceptado' }, { merge: true });

}

export const refuseTutorCandidate = async (id) => {
  const candidateRef = doc(db, 'candidatosTutores', id);
  setDoc(candidateRef, { status: 'Rechazado' }, { merge: true });
}

export const getTutorsCandidate = async () => {

     const querySnapshot = await getDocs(query(collection(db, "candidatosTutores"), orderBy("score","desc"), orderBy("name", "asc")));
     return querySnapshot;

}

export const getUserById = async (id) => {
  const querySnapshot = await getDocs(query(collection(db, "users"),  
    where("uid", "==", id),
    limit(1)));
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0];
  } else {
      return null;
  }
}

export const getUserByEmail = async (email) => {
  const querySnapshot = await getDocs(query(collection(db, "users"),  
    where("email", "==", email),
    limit(1)));
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0];
  } else {
      return null;
  }
}

