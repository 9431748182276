
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

import Logo from "../assets/img/pages/loading.gif";


export default (props) => {

  const { show } = props;

  return (
    
    <div style={{backgroundColor:'#FFFFFF'}} className={`preloader flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
     {show && <> <Image src={Logo}  />
      <h1 style={{color: '#5F4D9E', fontSize:60}}><b>Cargando ...</b></h1> </>}
    </div>
    
  );
};
