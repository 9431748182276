import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';

import ReactExport from "react-export-excel";

import { StudentsCandidatesTable } from "../../components/Tables";



import { getStudentsCandidate } from '../../services/students';

 



export default () => {

  const [studentsCandidates, setStudentsCandidates] = useState([]);
  const [place, setPlace] = useState('Aguascalientes');

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


  useEffect( () => {


    async function fetchData() {
      
      const studentsCandidates = await getStudentsCandidate(place);
      let arrayStudentsCandidates = [];

      studentsCandidates.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        arrayStudentsCandidates.push({id:doc.id, data: doc.data() });
        console.log(doc.id, " => ", doc.data());
      });

      setStudentsCandidates(arrayStudentsCandidates);
    }
    fetchData();




  }, [place]);



  return (
    <>


    
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Candidatas a alumnas</h4>
          <p className="mb-0">Busca y selecciona a los candidatos más destacados.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         <ButtonGroup>
         <ExcelFile  filename="Relación de candidatas"
  
  element={ <Button  variant="outline-primary" size="sm">Exportar a excel</Button>}>
                <ExcelSheet data={studentsCandidates} name="Candidatas">
                    <ExcelColumn label="ID" value={(col) => col.id} />
                    <ExcelColumn label="Nombre" value={(col) => col.data.name} />
                    <ExcelColumn label="Estado" value={(col) => col.data.place} />
                    <ExcelColumn label="Edad" value={(col) => col.data.candidateAge} />
                    <ExcelColumn label="Correo" value={(col) => col.data.email} />
                    <ExcelColumn label="Escuela" value={(col) => col.data.school} />
                    <ExcelColumn label="¿Falta prueba lógica?" value={(col) => col.data.finishTest?'Sí':'No'} />
                    <ExcelColumn label="¿Falta video presentación?" value={(col) => col.data.video!=undefined?'Sí':'No'} />

                </ExcelSheet>
            </ExcelFile>
          </ButtonGroup> 
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">

        <Form.Group id="place" className="mb-4">
                    <Form.Label>Filtrar por estado:</Form.Label>
                    <InputGroup style={{width: 300}}>
                    <Form.Select   value={place}  onChange={evt => setPlace(evt.target.value)}>
                        <option defaultValue value={''}>Selecciona una opción</option>
                        <option>Aguascalientes</option>
                        <option>Baja California</option>
                        <option>Baja California Sur</option>
                        <option>Campeche</option>
                        <option>Chiapas</option>
                        <option>Chihuahua</option>
                        <option>Ciudad de México</option>
                        <option>Coahuila</option>
                        <option>Colima</option>
                        <option>Durango</option>
                        <option>Estado de México</option>
                        <option>Guanajuato</option>
                        <option>Guerrero</option>
                        <option>Hidalgo</option>
                        <option>Jalisco</option>
                        <option>Michoacán</option>
                        <option>Morelos</option>
                        <option>Nayarit</option>
                        <option>Nuevo León</option>
                        <option>Oaxaca</option>
                        <option>Puebla</option>
                        <option>Querétaro</option>
                        <option>Quintana Roo</option>
                        <option>San Luis Potosí</option>
                        <option>Sinaloa</option>
                        <option>Sonora</option>
                        <option>Tabasco</option>
                        <option>Tamaulipas</option>
                        <option>Tlaxcala</option>
                        <option>Veracruz</option>
                        <option>Yucatán</option>
                        <option>Zacatecas</option>
                    </Form.Select>
                    </InputGroup>
                  </Form.Group>
         {/**  <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Buscar" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>  */}
        </Row>
      </div>

      <StudentsCandidatesTable tutorsCandidates={studentsCandidates} place={place}/> 
    </>
  );
};
