import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBook, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Card, Form, Button, ListGroup,InputGroup } from '@themesberg/react-bootstrap';

import ReactExport from "react-export-excel";

import { getWords,disableWord,enableWord } from '../../services/dictionary';
import moment from "moment";
import 'moment/locale/es';




export default () => {

  const user = JSON.parse(sessionStorage.getItem("userLoged"));

  const [sessions, setSessions] = useState([]);
  const [status, setStatus] = useState('Activas');
  const [title, setTitle] = useState(user.role === 'admin' || user.role === 'super'?
                                    'Palabras activas':'Palabras agregadas recientemente');



  useEffect( () => {
    async function fetchData() {
      const wordsResolve = await getWords(status);
      let words = [];
      wordsResolve.forEach((word) => {
 
        words.push({id:word.id, data: word.data() });
        console.log(word.id, " => ", word.data());
      });
      setSessions(words);
    }
    fetchData();
  }, [status]);


  const changeStatus = (status) => {
    setStatus(status);
    if(status === 'Activas') setTitle('Palabras activas');
    if(status === 'Inactivas') setTitle('Palabras inactivas');

  }


  const getMonth = (timestamp) =>{
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    return months[moment(timestamp.toDate(), 'DD-MM-YYYY').format('M')-1];
  }

  const disableEnableSession = async (session) => {
    let sessionUpdateResolve;
    if(session.data.active){
      sessionUpdateResolve = await disableWord(session.id);
    }else{
      sessionUpdateResolve = await enableWord(session.id);
    }
    
    const sessionsResolve = await getWords(status);
    let sessions = [];
    sessionsResolve.forEach((session) => {

      sessions.push({id:session.id, data: session.data() });
      console.log(session.id, " => ", session.data());
    });
    setSessions(sessions);

    
  }



  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Diccionario colectivo</h4>
          <p className="mb-0">Crea y revisa las palabras agregadas al diccionario colectivo.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
       {/* <ButtonGroup>
         <ExcelFile  filename="Relación de alumnas"
  
  element={ <Button  variant="outline-primary" size="sm">Exportar a excel</Button>}>
                <ExcelSheet data={sessions} name="Candidatas">
                    <ExcelColumn label="Folio" value={(col) => col.id} />
                    <ExcelColumn label="Nombre" value={(col) => col.data.name} />
                    <ExcelColumn label="Estado" value={(col) => col.data.place} />
                    <ExcelColumn label="Edad" value={(col) => col.data.candidateAge} />
                    <ExcelColumn label="Correo" value={(col) => col.data.email} />
                    <ExcelColumn label="Escuela" value={(col) => col.data.school} />
                    <ExcelColumn label="Turno" value={(col) => col.data.schoolShift} />
                    <ExcelColumn label="¿Prueba lógica?" value={(col) => col.data.finishTest?'Sí':'No'} />
                    <ExcelColumn label="¿Video?" value={(col) => col.data.video!=undefined?'Sí':'No'} />
                    <ExcelColumn label="Status" value={(col) => col.data.status} />

                </ExcelSheet>
            </ExcelFile>
          </ButtonGroup> */}
        </div>
      </div>

      <div className="table-settings mb-4">

      

       


        <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">{title}</h5>


        <InputGroup style={user.role === 'admin' || user.role === 'super'?{width:300}:{width: 200}}>
                  {(user.role === 'admin' || user.role === 'super') &&
                    <Form.Select   value={status}  onChange={evt => changeStatus(evt.target.value)}>
                      <option>Activas</option>
                      <option>Inactivas</option>
                    </Form.Select>
                  }

                   
                      <Button onClick={() => window.location.replace("#/dashboard/word/new") }  variant="outline-primary" size="md"><FontAwesomeIcon icon={faBook} className="me-2"/>
                       Crear palabra
                    </Button>
                    
                    </InputGroup> 
      </Card.Header>
      <Card.Body>
        <ListGroup className="list-group-flush list my--3">
          {sessions.map(session => 
          <ListGroup.Item className="px-0">
            <div class="align-items-center d-block d-sm-flex border-bottom border-light pb-4 mb-4 row">
              <div class="col">
              <span><h6>{session.data.word}</h6></span>
                <span>{session.data.definition}</span>
                {/*<div class="small fw-bold mt-1">{moment(session.data.created.toDate()).format('DD/MM/yyyy hh:mm A')}</div> */}
                <div class="small fw-bold mt-1"><br/>
                  Publicado {(moment(session.data.created.toDate())).locale('es').fromNow()}
                  <span class="fw-bold"> por <b>{session.data.createdBy.name}</b></span>
                  </div>
                
              </div>
              <div class="col">
             

            

                   {(user.role === 'admin' || user.role === 'super') && <>
                    <Button  variant={session.data.active?"outline-danger":"outline-success"} 
                    size="md" style={{float:'right'}} onClick={() => disableEnableSession(session)}>
                    <FontAwesomeIcon icon={session.data.active?faTimes:faCheckCircle} className="me-2"/>
                       {session.data.active?'Desactivar':'Activar'}
                    </Button> 
                    <Button  variant="outline-primary" 
                    onClick={() => window.location.replace("#/dashboard/word/"+session.id)}
                    size="md" style={{float:'right',marginRight:10}} >
                      <FontAwesomeIcon icon={faEdit}/>
                       
                    </Button>
                    </>
                  }

                  {(user.role === 'student' || user.role === 'tutor') 
                  && session.data.createdBy.email === user.email && <>
                    <Button  variant="outline-primary" 
                    onClick={() => window.location.replace("#/dashboard/word/"+session.id)}
                    size="md" style={{float:'right',marginRight:10}} >
                      <FontAwesomeIcon icon={faEdit}/> Editar
                       
                    </Button>
                    </>
                  }
                 

                    

                  
              </div>
            </div>
        </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>


      </div>

     
    </>
  );
};

