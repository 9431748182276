import React, { useState, useEffect } from "react";
import { Button, ButtonGroup} from '@themesberg/react-bootstrap';
import { TutorsTable } from "../../components/Tables";
import { getTutors } from '../../services/tutors';

export default () => {

  const [tutors, setTutors] = useState([]);


  useEffect( () => {


    async function fetchData() {


      
      const tutors = await getTutors();
      let arrayTutors = [];

      tutors.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        arrayTutors.push({id:doc.id, data: doc.data() });
        console.log(doc.id, " => ", doc.data());
      });

      setTutors(arrayTutors);
    }
    fetchData();




  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Tutores</h4>
          <p className="mb-0">Selecciona a los candidatos más destacados.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         {1 == 2 && <ButtonGroup>
            <Button variant="outline-primary" size="sm">Exportar a excel</Button>
          </ButtonGroup>}
        </div>
      </div>

      <div className="table-settings mb-4">
        { /** 
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Buscar" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>  */}
      </div>

      <TutorsTable tutors={tutors} />
    </>
  );
};
