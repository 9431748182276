import React, { useState, useEffect } from "react";

import { Col, Row} from '@themesberg/react-bootstrap';

import ReactExport from "react-export-excel";

import { StudentsInGroupTable } from "../../components/Tables";

//OJO AQUI se debe cambiar a getStudentRegistered una vez que se registren todos los usuarios de alumnas.
import { getStudentRegistered, getStudentAcepted, getStudentsOfTutorByEmail  } from '../../services/students';
import { getUserById, getUserByEmail } from '../../services/users';

export default () => {


  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [tutors, setTutors] = useState([]);
  const [tutorSelected, setTutorSelected] = useState({});
  const [students, setStudents] = useState([]);
  const [studentsOfTutor, setStudentsOfTutor] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("userLoged"));

 
  useEffect( () => {


    async function fetchData() {


      let studentsOfTutor =[];
      let emailTutor;
      
       let arrayStudents= [];
      if(user.role === "tutor") {
        emailTutor = user.email;
      }

      if(user.role === "student"){
        let email = user.email;
        const student = await getStudentRegistered(email);

        if(student===null || student.data().tutor === undefined){
          setStudentsOfTutor(arrayStudents);
          setTutorSelected({id:'', data: {name:' Sin grupo de tutoría '}}); 
          return null
        }
        emailTutor = student.data().tutor.email;
      }

      //setTutorSelected  id del tutor xm4DoRtb2spxOBt0wdj2

     
      const tutor = await getUserByEmail(emailTutor);
      setTutorSelected({id:tutor.id, data: tutor.data() }); 
      studentsOfTutor = await getStudentsOfTutorByEmail(tutor.data().email);
  
      studentsOfTutor.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        arrayStudents.push({id:doc.id, data: doc.data() });
        console.log(doc.id, " => ", doc.data());
      });

      setStudentsOfTutor(arrayStudents);

    }
    fetchData();




  }, [refresh]);


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Tutoría</h4>
          <p className="mb-0">Revisa todo lo relacionado a tu grupo de tutoría.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
      
        </div>
      </div>

      <div className="table-settings mb-4 offset-6">


      </div>
      <Row className="justify-content-between">



      <Col xs={12} md={12} lg={12} xl={12} style={{marginTop:10}}>
       <StudentsInGroupTable students={studentsOfTutor} 
        tutorSelected={tutorSelected}/>
      </Col>

      

      
      </Row>
    </>
  );
};
