import { collection,addDoc,getDocs,query,doc,getDoc,orderBy,setDoc,where,arrayUnion, db } from "../Firebase";

        export const getNotices = async (status, priority) => {
         
          let isActive;
          if(status === 'Activos') isActive= true;
          if(status === 'Inactivos') isActive= false;
         
          const querySnapshot = await getDocs(query(collection(db, "notices"),
                        where("active","==",isActive),
                        priority==='Todos'?where("active","==",isActive):where("priority","==",priority),
                        orderBy("created","asc")));
          return querySnapshot;
      
        }

        export const getNotice = async (sessionId) => {
          const docRef = await doc(db, 'notices', sessionId);
           return await getDoc(docRef);
        }
      
      export const disableNotice = async (id) => {  
           const sesionRef = doc(db, 'notices', id);
           const docRef = await setDoc(sesionRef, { active: false}, { merge: true });
           return docRef;
      }
      
      export const enableNotice = async (id) => {  
        const sesionRef = doc(db, 'notices', id);
        const docRef = await setDoc(sesionRef, { active: true}, { merge: true });
        return docRef;
      }
      
      export const createNotice = async (sesion) => {
         const docRef = await addDoc(collection(db, "notices"),sesion);
           console.log("Document written with ID: ", docRef.id);
           return docRef;
      }
      
      
      export const updateNotice = async (id, sesion) => {
        const sesionRef = doc(db, 'notices', id);
        setDoc(sesionRef, sesion, { merge: true });
      }

      export const markAsOpened = async (userId, messageId) => { 
       // user.email,messageId

       const candidateRef = doc(db, 'users', userId);
  return setDoc(candidateRef, { messages: arrayUnion(messageId)}, { merge: true });


      }
      

