import { createUserWithEmailAndPassword,sendPasswordResetEmail,collection,auth,addDoc,getDocs,
         query,doc,orderBy,setDoc,where, db } from "../Firebase";

export const registerTutorsCandidate = async (tutorsCandidate) => {
   // alert(JSON.stringify(tutorsCandidate));
    const docRef = await addDoc(collection(db, "candidatosTutores"),tutorsCandidate);
      console.log("Document written with ID: ", docRef.id);
      return docRef;
}

export const acceptTutorCandidate = async (id) => {  
     const candidateRef = doc(db, 'candidatosTutores', id);
     setDoc(candidateRef, { status: 'Aceptado' }, { merge: true });

}

export const createTutor = async (idCandidate,name,email) => {  
  createUserWithEmailAndPassword(auth, email, "TemporalTalentum2021")
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
     // alert("response user CREATED "+JSON.stringify(user));
     // user.uid
     // const docRef = await addDoc(collection(db, "candidatosTutores"),tutorsCandidate);


     const userRef = doc(db, 'users', user.uid);
     // Analert('userRef '+JSON.stringify(userRef));

     setDoc(userRef, {'role':'tutor', 'uid':user.uid,'name': name, 'email':user.email}).then((response) => {
      // alert(JSON.stringify("DOCUMENT CREATED "+response));
      sendPasswordResetEmail(auth,email).then((response) => {
       
        const candidateRef = doc(db, 'candidatosTutores', idCandidate);
        setDoc(candidateRef, { status: 'Registrado' }, { merge: true });

      }).catch(function(error) {
        alert(error.message);
        // An error happened.
      });
    }).catch(function(error) {
      alert(error.message);
      // An error happened.
    });

     
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });

}

export const refuseTutorCandidate = async (id) => {
  const candidateRef = doc(db, 'candidatosTutores', id);
  setDoc(candidateRef, { status: 'Rechazado' }, { merge: true });
}

export const getTutorsCandidate = async () => {

     const querySnapshot = await getDocs(query(collection(db, "candidatosTutores"),  where("status", "in", ['Nuevo', 'Rechazado']),  orderBy("score","desc"), orderBy("name", "asc")));
     return querySnapshot;

}

export const getTutors = async () => {

  const querySnapshot = await getDocs(query(collection(db, "candidatosTutores"), where("status", "in", ['Aceptado','Registrado']), orderBy("score","desc"), orderBy("name", "asc")));
  return querySnapshot;

}

export const getTutorsByName = async () => {

  const querySnapshot = await getDocs(query(collection(db, "candidatosTutores"), where("status", "in", ['Aceptado','Registrado']), orderBy("name", "asc")));
  return querySnapshot;

}

